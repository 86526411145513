<template>
  <a :href="href" class="ta-link-button d-flex align-items-center rounded bg-light py-1 px-3" @click="$emit('click', $event)">
    <b-icon v-if="icon" :icon="icon" aria-hidden="true"/>
    <span style="flex: 1">
      <slot></slot>
    </span>
    <b-icon icon="chevron-right" aria-hidden="true"/>
  </a>
</template>

<script>
export default {
  name: "LinkButton",
  props: {
    href: {
      type: [String, Array],
      default: 'javascript:void(0)'
    },
    icon: String
  }
}
</script>

<style lang="scss" scoped>
.ta-link-button {
  display: inline-block;
  width: 280px;
}
</style>
