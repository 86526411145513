export default {
  props: {
    auction: {
      required: true,
      type: Object
    },
  },
  computed: {
    auctionViewRoute() {
      if (this.$route.name === 'PrivateTreatyAuctions') {
        return {name: 'PrivateTreatyAuctionView', params: {uuid: this.auction.uuid}}
      }
      return {name: 'AuctionViewTimed', params: {uuid: this.auction.uuid}}
    }
  },
}
