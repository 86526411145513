<template>
  <InputWidget
    v-model="model"
    :error="errors"
    id="input-bank_account_address_1"
    type="text"
    :label="computedLabel"
    :placeholder="computedPlaceholder"
    @change="inputChange"
    rules=""
  />
</template>

<script>
import InputWidget from "../../../core/InputWidget";
import BaseBankAccountMixin from "../../../../mixins/baseBankAccountMixin";

export default {
  name: "InputSignupBankAccountAddress1",
  components: {InputWidget},
  mixins: [BaseBankAccountMixin],

  data() {
    return {
      defaultLabel: this.$t("Address 1")
    }
  }
}
</script>

<style scoped>

</style>
