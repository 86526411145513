<template>
  <div class="ta-page-items">
    <PageHeader :title="$t('Items')"></PageHeader>
    <div class="d-flex justify-content-start">
      <div class="ta-item-list">
        <div class="ta-item-list-header">
          <div class="ta-per-page-wrapper">
            {{ $t("Show") }}
            <b-form-select
              class="mx-2"
              v-model="limit"
              @change="updatePagination"
              :options="perPageOptions"
            ></b-form-select>
            {{ $t("entries") }}
          </div>
          <div class="ta-item-search-wrapper d-flex align-items-center">
            {{ $t("Search") }}:
            <b-form-input
              :placeholder="$t('Search for items')"
              class="ml-2"
              v-model="filters.q"
              v-debounce:300ms="updateRoute"
            ></b-form-input>
          </div>
        </div>
        <div v-if="items.loading" class="ta-item-loader">
          <b-spinner label="Spinning"></b-spinner>
          <span class="mt-2">{{ $t("Loading...") }}</span>
        </div>
        <template v-if="!items.loading">
          <div class="ta-item-list-wrapper">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">
                    {{ $t("Thumbnail") }}
                  </th>
                  <th scope="col">
                    {{ $t("Title") }}
                  </th>
                  <th scope="col">
                    {{ $t("Department") }}
                  </th>
                  <th scope="col">
                    {{ $t("Category") }}
                  </th>
                  <th scope="col">
                    {{ $t("Quantity") }}
                  </th>
                  <th scope="col">
                    {{ $t("Low") }}
                  </th>
                  <th scope="col">
                    {{ $t("High") }}
                  </th>
                  
                  <th scope="col">
                    {{ $t("Start Price") }}
                  </th>
                  <th scope="col">
                    {{ $t("Reserve") }}
                    </th>
                  <th scope="col">
                    {{ $t("Currency") }}
                  </th>
                  <th scope="col">
                    {{ $t("Status") }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="items.results.length">
                <tr v-for="item in items.results" :key="item.uuid">
                  <td></td>
                  <td>
                    <b-img width="100" :src="item.images.length > 0 && Object.keys(item.images[0].data).length !== 0 ? item.images[0].data.thumb.url : 'https://cdn.artisio.co/no_image.jpg'" fluid></b-img>
                  </td>
                  <td v-html="item.short_description">
                  </td>
                  <td>
                    {{item.department.name | currentLocale}}
                  </td>
                  <td>
                    {{item.category.name | currentLocale}}
                  </td>
                  <td>
                    {{ item.quantity }}
                  </td>
                  <td>
                    {{ item.low | asCurrency(item.branch.currency.code) }}
                  </td>
                  <td>
                    {{ item.high | asCurrency(item.branch.currency.code) }}
                  </td>
                  <td>
                    {{ item.start_price | asCurrency(item.branch.currency.code) }}
                  </td>
                  <td>
                    {{ item.reserve | asCurrency(item.branch.currency.code) }}
                  </td>
                  <td>
                    {{item.branch.currency.code}}
                  </td>
                  <td>
                    <div v-if="item.statuses && item.statuses.length > 0">
                      <small v-for="status in item.statuses" :key="status.name" class="badge"
                             :class="{
                                'badge-danger': status.status === 'unsold',
                                'badge-success': status.status === 'sold',
                                'badge-info': status.status === 'reserved',
                                'badge-primary': status.status === 'lotted'
                              }">
                        {{ status.display_name }}
                      </small>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="12">
                  <p class="text-muted text-center py-3">
                    {{ $t(`You don't have data`) }}
                  </p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="ta-item-list-footer">
            <div class="ta-item-pagination-summary">
              {{
                $t("Showing {start} to {finish} of {total} entries", {
                  start:
                    (items.pagination.page - 1) * items.pagination.limit +
                    1,
                  finish:
                    items.pagination.page * items.pagination.limit >
                    items.totalRows
                      ? items.totalRows
                      : items.pagination.page * items.pagination.limit,
                  total: items.totalRows,
                })
              }}
            </div>
            <b-pagination
              v-model="page"
              :total-rows="items.totalRows"
              :per-page="items.pagination.limit"
              @input="updatePagination"
              class="ta-item-list-pagination"
            ></b-pagination>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n';
import { mapState, mapGetters, mapActions } from "vuex";
import {
  ITEM_PAGINATION_PAGE,
  ITEM_PAGINATION_LIMIT,
} from "../constants";
import logger from "../logger";
import PageHeader from "@/components/PageHeader";

export default {
  name: "Items",
  components: {
    PageHeader
  },
  data: () => {
    return {
      limit: ITEM_PAGINATION_LIMIT,
      page: ITEM_PAGINATION_PAGE,
      filters: {
        q: null,
      },
      modal: {
        show: false,
        title: '',
        base64: ''
      },
      delayedRequest: null,
      query: null,
      normalizedQuery: null
    };
  },

  computed: {
    ...mapState(["perPageOptions", "items"]),
    ...mapGetters(["currentLocale"]),
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newVal) {
        // make actions with newVal.page
        if (newVal.page) this.page = newVal.page;
        if (newVal.limit) this.limit = newVal.limit;

        for (const key in this.filters) {
          if (newVal[key]) this.filters[key] = newVal[key];
        }

        this.getUpdatedItems();
      },
    },
  },

  methods: {
    ...mapActions(["getItems", "setItemPagination"]),

    filtersChanged(filters) {
      this.filters = filters;
      this.updateRoute();
    },

    updatePagination() {
      this.updateRoute();
    },

    updateRoute() {
      this.$router
        .push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            page: this.page,
            limit: this.limit,
            ...this.filters,
          },
        })
        .catch((err) => {
          logger.error("Failed to update route for items", err);
        });
    },

    getUpdatedItems() {
      this.getItems({
        pagination: { page: this.page, limit: this.limit },
        filters: this.filters,
      });
    }
  },
};
</script>

<style scoped lang="scss">
.ta-item-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.ta-item-loader {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ta-per-page-wrapper {
  display: flex;
  align-items: center;
}

.ta-item-list {
  flex: 1;
}

.ta-item-list-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ta-uc-first {
  text-transform: capitalize;
}

.pagination.ta-item-list-pagination {
  margin-bottom: 0 !important;
}
</style>
