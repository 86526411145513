import BaseInputMixin from "./baseInputMixin";

export default {
  mixins: [BaseInputMixin],
  props: {
    bankAccountFilled: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
