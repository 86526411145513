<template>
  <small class="text-muted">{{ auction.number_of_lots || 0 }} {{ $t('lots') }}</small>
</template>

<script>
export default {
  name: "NumberOfLot",
  props: {
    auction: {
      required: true,
      type: Object
    },
  },
}
</script>

<style scoped>

</style>
