<template>
  <buy-now-button v-if="lot.buy_now_price"
                  :lot="lot"
                  :disabled="buyNowDisabled"
                  @confirm="place_bid"
                  :bidding-started="biddingStarted"
                  :bidding-finished="biddingFinished"/>
</template>

<script>
import {mapState} from "vuex";
import BuyNowButton from "../../lot-view/sidebar/BuyNowButton";
import lotBiddingMixin from "../../../mixins/lotBiddingMixin";

export default {
  name: "BiddingBuyNowButton",
  components: {BuyNowButton},
  mixins: [lotBiddingMixin],
  computed: {
    ...mapState({
      lot: state => state.lotObject.data,
    })
  }
}
</script>

<style scoped>

</style>
