<template>
  <abstract-signup-text-input
    :id="`input-${type}_address_address_2`"
    v-model="model"
    :errors="errors"
    :label="computedLabel"
    :placeholder="computedPlaceholder"
    @change="inputChange"
    rules=""
  />
</template>

<script>
import BaseSignupTextInput from "../core/AbstractSignupTextInput";
import AbstractSignupTextInput from "../core/AbstractSignupTextInput";
import BaseAddressMixin from "../../../../mixins/baseAddressMixin";

export default {
  name: "InputSignupAddress2",
  components: {AbstractSignupTextInput, BaseSignupTextInput},
  mixins: [BaseAddressMixin],

  data() {
    return {
      defaultLabel: this.$t("Address 2")
    }
  }
}
</script>

<style scoped>

</style>
