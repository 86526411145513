<template>
  <InputWidget
    v-model="model"
    :error="errors"
    id="input-email"
    type="email"
    :label="computedLabel"
    rules="required|email"
    :placeholder="computedPlaceholder"
    @change="inputChange"
    class="mb-3"
  >
  </InputWidget>
</template>

<script>
import InputWidget from "../../../core/InputWidget";
import BaseInputMixin from "../../../../mixins/baseInputMixin";

export default {
  name: "InputSignupEmail",
  components: {InputWidget},
  mixins: [BaseInputMixin],
  data() {
    return {
      defaultLabel: this.$t('Email Address'),
      defaultPlaceholder: 'name@example.com'
    }
  },
}
</script>

<style scoped>

</style>
