<template>
  <b-form-checkbox
    class="mb-2 text-nowrap"
    id="sameAsBilling"
    v-model="model"
    value="accepted"
    unchecked-value="not_accepted"
    @change="inputChange"
  >
    {{ computedLabel }}
  </b-form-checkbox>
</template>

<script>
import BaseInputMixin from "../../../../mixins/baseInputMixin";

export default {
  name: "InputSignupSameAsBillingAddress",
  mixins: [BaseInputMixin],

  data() {
    return {
      defaultLabel: this.$t("Same As Billing Address")
    }
  },

  props: {
    errors: {
      required: false,
      default: null
    }
  }
}
</script>

<style scoped>

</style>
