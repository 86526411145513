import Vue from 'vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import vueDebounce from 'vue-debounce'
import VueCookie from 'vue-cookie';
import VueNativeSock from 'vue-native-websocket'
import App from './App.vue'
import router from './router'
import store from "./store";
import i18n from './i18n';
import './filters';
import './plugins/daterange-picker';
import './plugins/vee-validate';
import './scss/main.scss'
import './components/configuration'
import {hideHTMLElements, showHTMLElements} from "./helpers";
import CONFIG from "./user-config";
import {SOCKET_RECONNECT_DELAY} from "@/constants";

import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// Import Bootstrap an BootstrapVue CSS files (order is important)

// (UNCOMMENT DURING DEVELOPMENT!!!)
// import './bootstrap/bootstrap.scss';
// import 'bootstrap/scss/bootstrap.scss'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(vueDebounce)
Vue.use(VueCookie);

Vue.config.productionTip = false

Vue.use(VueNativeSock, process.env.VUE_APP_WEBSOCKET_SERVER, {
  store: store,
  format: 'json',
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  // reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: SOCKET_RECONNECT_DELAY, // (Number) how long to initially wait before attempting a new (1000)
})

const el = document.getElementById('artisioTimedAuctions')


store.dispatch('getSettings');

store.dispatch('socketLoginCallback')
  .then(() => {
    if (!store.state.is_authorized) {
      hideHTMLElements(CONFIG.visibleForAuthSelector);
      showHTMLElements(CONFIG.visibleForGuestsSelector);
    } else {
      showHTMLElements(CONFIG.visibleForAuthSelector);
      hideHTMLElements(CONFIG.visibleForGuestsSelector);
    }

    store.state.user.data = JSON.parse(localStorage.getItem('user'));
    store.state.user.token = Vue.prototype.$cookie.get(CONFIG.artisioCookieName);
  })

window.artisio = window.artisio || {};
const init = () => {
  if (!el) {
    console.error(`Artisio Webapp HTML Element with id "artisioTimedAuctions" does not exist`)
  } else {
    let innerDiv = el.children[0];
    innerDiv = innerDiv || document.createElement('div');
    el.appendChild(innerDiv);
    window.artisio.app = new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount(innerDiv)
  }
}

const destroy = () => {
  window.artisio.app.$destroy()
  window.artisio.app = null;
  el.innerHTML = '';
}

window.artisio.init = init;
window.artisio.destroy = destroy

init();
