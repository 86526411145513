<template>
  <InputWidget
    v-model="model"
    :error="errors"
    id="input-password-confirmation"
    type="password"
    :show-password-toggle="false"
    :label="computedLabel"
    :placeholder="computedPlaceholder"
    rules="required"
    @change="inputChange"
    auto-complete="off"
  />
</template>

<script>
import BaseInputMixin from "../../../../mixins/baseInputMixin";
import InputSignupPassword from "./InputSignupPassword";
import InputWidget from "../../../core/InputWidget";

export default {
  name: "InputSignupPasswordConfirmation",
  components: {InputWidget, InputSignupPassword},
  mixins: [BaseInputMixin],
  data() {
    return {
      defaultLabel: this.$t("Password Confirmation")
    }
  }
}
</script>

<style scoped>

</style>
