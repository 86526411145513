import CONFIG from "../user-config";
import {
  AUCTION_DEFAULT_SORT_BY,
  AUCTION_DEFAULT_SORT_FIELD,
  AUCTION_DEFAULT_STATUS,
  AUCTION_LIST_DEFAULT_LIMIT,
  AUCTION_LIST_DEFAULT_PAGE,
  FAVORITE_AUCTION_DEFAULT_SORT_BY,
  FAVORITE_AUCTION_DEFAULT_SORT_FIELD,
  FAVORITE_AUCTION_DEFAULT_STATUS,
  FAVORITE_AUCTION_LIST_DEFAULT_LIMIT,
  FAVORITE_AUCTION_LIST_DEFAULT_PAGE,
  FAVORITE_LOT_DEFAULT_SORT_BY,
  FAVORITE_LOT_DEFAULT_SORT_FIELD,
  FAVORITE_LOT_DEFAULT_STATUS,
  FAVORITE_LOT_LIST_DEFAULT_LIMIT,
  FAVORITE_LOT_LIST_DEFAULT_PAGE,
  INVOICE_PAGINATION_LIMIT,
  INVOICE_PAGINATION_PAGE,
  ITEM_PAGINATION_LIMIT,
  ITEM_PAGINATION_PAGE,
  LOT_DEFAULT_SORT_BY,
  LOT_DEFAULT_SORT_FIELD, LOT_DEFAULT_STATUS,
  LOT_LIST_DEFAULT_LIMIT,
  LOT_LIST_DEFAULT_PAGE,
  SETTLEMENT_PAGINATION_LIMIT,
  SETTLEMENT_PAGINATION_PAGE,
  TEMPORARY_ITEM_PAGINATION_LIMIT,
  TEMPORARY_ITEM_PAGINATION_PAGE
} from "../constants";

let doNotShowConfirmOnBid = sessionStorage.getItem('ARTISIO_DO_NOT_SHOW_CONFIRM_ON_BID') || null;
if (doNotShowConfirmOnBid) {
  doNotShowConfirmOnBid = JSON.parse(doNotShowConfirmOnBid)
}

export const LOT_DEFAULT_FILTERS = {
  q: '',
  department: [],
  category: [],
  reserve_low: '',
  reserve_high: '',
  estimate_low: '',
  estimate_high: '',
  withImages: false,
  lot_no_from: '',
  lot_no_to: '',
  collection_info_states: [],
  collection_info_municipalities: [],
  page: LOT_LIST_DEFAULT_PAGE,
  limit: LOT_LIST_DEFAULT_LIMIT,
  sort: LOT_DEFAULT_SORT_FIELD,
  'sort-by': LOT_DEFAULT_SORT_BY,
  lot_status: LOT_DEFAULT_STATUS
};

export const FAVORITE_LOT_DEFAULT_FILTERS = {
  page: FAVORITE_LOT_LIST_DEFAULT_PAGE,
  limit: FAVORITE_LOT_LIST_DEFAULT_LIMIT,
  sort: FAVORITE_LOT_DEFAULT_SORT_FIELD,
  'sort-by': FAVORITE_LOT_DEFAULT_SORT_BY,
  lot_status: FAVORITE_LOT_DEFAULT_STATUS
};

export const AUCTION_DEFAULT_FILTERS = {
  q: '',
  department: [],
  category: [],
  page: AUCTION_LIST_DEFAULT_PAGE,
  limit: AUCTION_LIST_DEFAULT_LIMIT,
  sort: AUCTION_DEFAULT_SORT_FIELD,
  'sort-by': AUCTION_DEFAULT_SORT_BY,
  status: AUCTION_DEFAULT_STATUS
}

export const FAVORITE_AUCTION_DEFAULT_FILTERS = {
  page: FAVORITE_AUCTION_LIST_DEFAULT_PAGE,
  limit: FAVORITE_AUCTION_LIST_DEFAULT_LIMIT,
  sort: FAVORITE_AUCTION_DEFAULT_SORT_FIELD,
  'sort-by': FAVORITE_AUCTION_DEFAULT_SORT_BY,
  status: FAVORITE_AUCTION_DEFAULT_STATUS
}

export default {
  // Configuration attributes added to root element of vue project
  mainConfig: CONFIG,
  timeDiff: null,
  is_authorized: false,
  signal: 0,
  customer: {},
  titles: [],
  countries: [],
  user: {
    token: null,
  },
  modalMsgBox: {
    visible: false,
    title: null,
    content: null,
    headerBgVariant: null,
    headerTextVariant: null,
  },
  settings: null,
  toast: {
    visible: false,
    duration: 0,
    title: null,
    text: null,
    variant: null,
  },
  perPageOptions: [
    5, 10, 25, 50, 100,
  ],
  auctions: {
    loading: false,
    pagination: {
      page: AUCTION_LIST_DEFAULT_PAGE,
      limit: AUCTION_LIST_DEFAULT_LIMIT,
    },
    totalRows: null,
    results: [],
    preserveOldLots: false,
    categories: [],
    departments: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  auctionObject: {
    loading: false,
    data: {},
  },
  lots: {
    loading: false,
    pagination: {
      page: LOT_LIST_DEFAULT_PAGE,
      limit: LOT_LIST_DEFAULT_LIMIT,
    },
    totalRows: null,
    results: [],
    preserveOldLots: false,
    categories: [],
    departments: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  lotObject: {
    loading: false,
    data: {},
    biddingStarted: false,
    biddingFinished: false,
    maxBidModal: false,
    upcomingBidPopover: {
      show: false,
      low: null,
      high: null,
    },
    upcomingMaxBidPopover: {
      show: false,
      low: null,
      high: null,
    },
  },
  departments: {
    loading: false,
    data: []
  },
  categories: {
    loading: false,
    data: []
  },
  dynamicFields: {
    loading: false,
    data: []
  },
  locationStates: {
    loading: false,
    data: []
  },
  lotQueryParams: JSON.parse(JSON.stringify(LOT_DEFAULT_FILTERS)),
  favoriteLotQueryParams: JSON.parse(JSON.stringify(FAVORITE_LOT_DEFAULT_FILTERS)),
  auctionQueryParams: JSON.parse(JSON.stringify(AUCTION_DEFAULT_FILTERS)),
  favoriteAuctionQueryParams: JSON.parse(JSON.stringify(FAVORITE_AUCTION_DEFAULT_FILTERS)),
  lotListViewType: localStorage.getItem('ARTISIO_LOT_LIST_VIEW_TYPE') || 'grid',
  auctionListViewType: localStorage.getItem('ARTISIO_AUCTION_LIST_VIEW_TYPE') || 'list',
  doNotShowConfirmOnBid: doNotShowConfirmOnBid || {},
  socket: {
    isOpened: false,
    loginFinished: false,
    disconnectTime: Date.now()
  },
  invoices: {
    loading: false,
    pagination: {
      page: INVOICE_PAGINATION_PAGE,
      limit: INVOICE_PAGINATION_LIMIT,
    },
    totalRows: 5,
    results: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  settlements: {
    loading: false,
    pagination: {
      page: SETTLEMENT_PAGINATION_PAGE,
      limit: SETTLEMENT_PAGINATION_LIMIT,
    },
    totalRows: 5,
    results: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  items: {
    loading: false,
    pagination: {
      page: ITEM_PAGINATION_PAGE,
      limit: ITEM_PAGINATION_LIMIT,
    },
    totalRows: 5,
    results: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  temporaryItems: {
    loading: false,
    pagination: {
      page: TEMPORARY_ITEM_PAGINATION_PAGE,
      limit: TEMPORARY_ITEM_PAGINATION_LIMIT,
    },
    totalRows: 5,
    results: [],
    filters: {
      params: null,
      urlParams: null,
    }
  },
  sellAnItemModal: {
    show: false
  },
  departmentsForSellAnItem: null,
  categoriesMap: {},
  dynamicFieldsMap: {},
  error: {
    code: null,
    message: null
  },
  filtersMenuOpenMobile: false,
  homePage: {
    departments: {
      loading: false,
      data: []
    }
  },
  isOnline: true
}
