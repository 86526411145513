import {getLotsQuery} from "@/helpers";

export default {
  props: {
    lot: {
      required: true,
      type: Object
    },
  },
  computed: {
    lotInnerPageRoute() {
      if (this.$route.name === 'PrivateTreatyLots') {
        return {
          name: 'PrivateTreatyLotView',
          params: {lotno: this.lot.uuid}
        }
      }
      return {name: 'LotView', params: {lotno: this.lot.uuid}, query:  getLotsQuery()}
    },
  },
}
