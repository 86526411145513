<template>
  <small class="text-nowrap d-block mb-1">
    <span class="ta-auction-no text-primary font-weight-bold">{{ auction.auction_no }}</span>
  </small>
</template>

<script>
export default {
  name: "AuctionNo",
  props: {
    auction: {
      required: true,
      type: Object
    },
  },
}
</script>

<style scoped>

</style>
