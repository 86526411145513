import { render, staticRenderFns } from "./SortingDropdown.vue?vue&type=template&id=6f3e36e7&scoped=true&"
import script from "./SortingDropdown.vue?vue&type=script&lang=js&"
export * from "./SortingDropdown.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f3e36e7",
  null
  
)

export default component.exports