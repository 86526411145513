<template>
  <div>
    <div v-if="mainConfig.enableWatchlist" :id="lot.uuid" v-b-tooltip.hover>
      <b-button
        :disabled="!is_authorized"
        variant="link"
        @click="
          toggleWatchlist({
            entity_name: 'lot',
            uuid: lot.uuid,
            is_favored: lot.is_favored,
          })
        " class="ta-btn-watchlist"
      >
        <b-icon v-if="!lot.is_favored" icon="heart"></b-icon>
        <b-icon v-else icon="heart-fill"></b-icon>
      </b-button>
    </div>
    <b-tooltip v-if="!is_authorized" :target="lot.uuid" container="artisioTimedAuctions">
      {{ $t('Please login to proceed') }}
    </b-tooltip>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "LotAddToWatchlist",
  props: {
    lot: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapState(['mainConfig']),
    ...mapGetters(['is_authorized']),
  },
  methods: {
    ...mapActions(["toggleWatchlist"]),
  }
}
</script>

<style scoped>

</style>
