<template>
  <div>
    <filter-group :title="$t('Categories')"
                  id="collapse-categories"
                  v-if="selectedDepartment"
                  class="ta-filter-group"
    >
      <checkbox-list v-model="model"
                     @change="filtersChanged"
                     :label="$t('Categories')"
                     :options="categoriesAsOptions"/>
    </filter-group>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {LOT_LIST_DEFAULT_PAGE} from "../../../constants";
import {updateLotsRoute} from "../../../helpers";
import FilterGroup from "../../FilterGroup";
import CheckboxList from "../../lot-filters/CheckboxList";
import userConfig from "@/user-config";

export default {
  name: "FilterCategoryList",
  components: {CheckboxList, FilterGroup},
  data: () => ({
    model: []
  }),
  computed: {
    ...mapState(['lotQueryParams']),
    ...mapGetters(['selectedDepartment', 'categoriesAsOptions'])
  },
  watch: {
    lotQueryParams() {
      this.model = this.lotQueryParams.category
    },
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    filtersChanged() {
      this.setLotFilters({
        category: this.model,
        page: LOT_LIST_DEFAULT_PAGE,
      });
      if (userConfig.autoApplyFilters) {
        updateLotsRoute();
      }
    }
  },
  mounted() {
    this.model = this.lotQueryParams.category
  }
}
</script>

<style scoped>

</style>
