<template>
  <InputWidget
    v-model="model"
    :error="errors"
    id="input-title"
    type="select"
    @change="inputChange"
    :label="computedLabel"
    :placeholder="computedPlaceholder"
    rules="required"
    :options="titleOptions"
  >
  </InputWidget>
</template>

<script>
import BaseInputMixin from "../../../../mixins/baseInputMixin";
import InputWidget from "../../../core/InputWidget";
import {mapGetters} from "vuex";

export default {
  name: "InputSignupTitle",
  components: {InputWidget},
  mixins: [BaseInputMixin],

  data() {
    return {
      defaultLabel: this.$t("Title",)
    }
  },

  computed: {
    ...mapGetters(["titleOptions"])
  }
}
</script>

<style scoped>

</style>
