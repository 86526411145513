<script>
import {mapActions, mapMutations, mapState} from "vuex";
import templatable from "@/mixins/templatable";
import LotListAndFilters from "@/components/LotListAndFilters";

export default {
  name: "Lots",
  components: {
    LotListAndFilters
  },
  mixins: [templatable('lotListTemplate')],
  props: {
    auctionUuid: {
      type: String,
      default: null
    }
  },
  data: () => ({
    template: `
        [[template]]
    `
  }),
  watch: {
    '$route.query': {
      immediate: true,
      handler() {
        this.emptyLotFilters();
        this.setLotFilters({
          ...this.$route.query,
          auction_uuid: this.auctionUuid
        });
        this.getLots();
        this.getDepartments();
      },
    },
  },
  computed: {
    ...mapState(['lotListViewType']),
  },
  methods: {
    ...mapActions(['getLots', 'getDepartments']),
    ...mapMutations(['setLotFilters', 'emptyLotFilters']),
  },
}
</script>
