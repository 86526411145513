<template>
  <div>
    <!--    <div v-if="auction.start_date && auction.end_date" class="mb-3">-->
    <!--      <auction-dates :auction="auction"/>-->
    <!--    </div>-->

    <div class="row">
      <div class="col-md-6">
        <table class="text-sm ta-auction-dates mb-2">
          <tbody>
          <tr v-if="auction.premium_terms.length === 1">
            <td class="pr-2 font-weight-bold">
              {{ $t(`Buyer's Premium`) }}:
            </td>
            <td class="font-weight-semibold">
              {{ auction.premium_terms[0].percent }}%
            </td>
          </tr>
          <tr>
            <td class="pr-2 font-weight-bold">
              {{ $t(`Currency`) }}:
            </td>
            <td class="font-weight-semibold">
              {{ auction.currency.display }} ({{ auction.currency.code }})
            </td>
          </tr>
          <tr v-if="locationInfo">
            <td class="pr-2 font-weight-bold">
              {{ $t(`Location Information`) }}:
            </td>
            <td class="font-weight-semibold">
              {{ locationInfo }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-6">
        <table class="text-sm ta-auction-dates mb-2">
          <tbody>
          <tr v-if="contactInfo">
            <td class="pr-2 font-weight-bold">
              {{ $t(`Contact Information`) }}:
            </td>
            <td class="font-weight-semibold">
              {{ contactInfo }}
            </td>
          </tr>
          <tr v-if="viewingInfo ">
            <td class="pr-2 font-weight-bold">
              {{ $t(`Viewing Information`) }}:
            </td>
            <td class="font-weight-semibold">
              {{ viewingInfo }}
            </td>
          </tr>
          <tr v-if="auction.viewing_start_date && auction.viewing_end_date">
            <td class="pr-2 font-weight-bold">
              {{ $t(`Viewing Dates`) }}:
            </td>
            <td class="font-weight-semibold">
              {{ auction.viewing_start_date | datetime }} - {{ auction.viewing_end_date | datetime }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row d-flex justify-content-between mt-2">
      <auction-increments-link/>
      <auction-buyers-premium-link/>
      <auction-terms-link/>
      <auction-catalog-link/>
    </div>
    <!--    <auction-viewing-dates :auction="auction"/>-->

  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import AuctionDates from "./AuctionDates.vue";
import AuctionDescription from "./AuctionDescription.vue";
import AuctionViewingDates from './AuctionViewingDates.vue';
import LinkButton from "@/components/LinkButton";
import AuctionIncrementsLink from "@/views/AuctionViewPage/AuctionDetails/AuctionIncrementsLink";
import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";
import AuctionBuyersPremiumLink from "@/views/AuctionViewPage/AuctionDetails/AuctionBuyersPremiumLink";
import AuctionCatalogLink from "@/views/AuctionViewPage/AuctionDetails/AuctionCatalogLink";
import AuctionTermsLink from "@/views/AuctionViewPage/AuctionDetails/AuctionTermsLink";

export default {
  components: {
    AuctionTermsLink,
    AuctionCatalogLink,
    AuctionBuyersPremiumLink,
    AuctionIncrementsLink,
    LinkButton, AuctionDescription, AuctionDates, AuctionViewingDates
  },
  mixins: [auctionViewMixin],
  name: "AuctionDetails",
  computed: {
    ...mapGetters(['currentLocale']),
    locationInfo() {
      return this.$options.filters.currentLocale(this.auction.auction_location_info)
    },
    contactInfo() {
      return this.$options.filters.currentLocale(this.auction.contact_info)
    },
    viewingInfo() {
      return this.$options.filters.currentLocale(this.auction.viewing_info)
    }
  }
};
</script>

<style></style>
