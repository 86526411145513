import {PAGING_TYPE_PAGINATION} from "./constants";

const TEMPLATES = {
  lotListItemTemplate: `
    <div class="ta-lot-list-item-image-wrapper">
        <lot-list-item-image :lot="lot" />
        <lot-notify-start-button :lot="lot" />
    </div>
    
    <div class="ta-lot-list-item-desc">
      <div class="ta-lotno-bids-info d-flex justify-content-between">
        <lot-no :lot="lot" />
        <lot-number-of-bids :lot="lot" />
      </div>
      <lot-list-item-title :lot="lot" />
      <lot-collection-information :lot="lot" />
      <lot-start-price :lot="lot" />
      <lot-buy-now-price :lot="lot" />
    </div>
    <div class="ta-lot-list-item-buttons">
      <lot-add-to-watchlist :lot="lot" />
      <lot-countdown-timer :lot="lot" />
    </div>
  `,
  favoriteLotListItemTemplate: `
    {{image}}
    <div class="ta-lot-list-item-desc">
      <div class="ta-lotno-bids-info d-flex justify-content-between">
        {{lotNumber}}
        {{numberOfBids}}
      </div>
      {{title}}
      {{collectionInformation}}
      {{startPrice}}
      {{buyNowPrice}}
    </div>
    <div class="ta-lot-list-item-buttons">
      {{addToWatchlist}}
      {{countdownTimer}}
    </div>
  `,
  lotFiltersTemplate: `
    <!-- Number of items found based on the search criteria -->
    <lot-list-items-count />
    <!-- Lot filtering by status radio list -->
    <filter-lot-status />
    <!-- Department List to filter lots based on -->
    <filter-department-list />
    <!-- Category list to filter lots based on. This component is only displayed if department is selected and categories are filtered by department -->
    <filter-category-list />
    <!-- State and municipality list to filter lots by -->
    <filter-location-list />
    <!-- Min/Max input fields to filter lots by price range -->
    <filter-price-range />
    <!-- Min/Max input fields to filter lots by reserve -->
    <filter-reserve-range />
    <!-- Min/Max input fields to filter lots by low/high estimate -->
    <filter-estimate-range />
    <!-- Min/Max input fields to filter lots by lot no range -->
    <filter-lot-range />
    <!-- Checkbox field to filter lots whether it has image or not -->
    <filter-with-image />
    <!-- The whole dynamic fields to filter lots -->
    <filter-dynamic-fields />
  `,
  lotListTemplate: `
    <lot-list-and-filters>
      <lot-list-filters />
      <div class="ta-lot-section">
        <div class="ta-lot-list-header-top">
          <lot-keyword-search />
          <jump-to-lot />
        </div>
        <hr class="mt-0">
        <div class="ta-lot-list-header ">
          <lot-list-per-page-select />
          <div class="ta-lot-search-wrapper mb-3">
            <sorting-dropdown/>
            <lot-list-switch-view/>
            <lot-list-filter-mobile-button/>
          </div>
        </div>
        <lot-list-items />
      </div>
    </lot-list-and-filters>
  `,
  favoriteLotListTemplate: `
    <lot-list-and-filters>
      <div class="ta-lot-section">
        <div class="ta-lot-list-header-top">
          <lot-keyword-search />
          <jump-to-lot />
        </div>
        <hr class="mt-0">
        <div class="ta-lot-list-header ">
          <lot-list-per-page-select />
          <div class="ta-lot-search-wrapper mb-3">
            <sorting-dropdown/>
            <lot-list-switch-view/>
            <lot-list-filter-mobile-button/>
          </div>
        </div>
        <favorite-lot-list-items />
      </div>
    </lot-list-and-filters>
  `,
  lotViewTemplate: `
    <lot-view-header>
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="ta-lot-breadcrumbs">
            <lot-view-breadcrumbs />
            <lot-view-jump-to-lot />
          </div>
        </div>
        <div class="col-md-4">
          <lot-view-prev-next-buttons />
        </div>
      </div>
    </lot-view-header>
    <div class="row ta-lot-view-wrapper">
      <div class="col-lg-8 ta-lot-view-content">
        <div class="ta-lot-title-wrapper">
          <lot-view-title/>
          <lot-view-lot-no/>
        </div>
        <lot-view-images/>
        <lot-view-social-share/>
        <lot-view-description class="d-none d-lg-block" />
      </div>
      <div class="col-lg-4 ta-lot-view-bidding">
        <lot-view-bidding/>
      </div>
      <div class="col-lg-8 ta-lot-description-mobile d-block d-lg-none mt-3">
        <lot-view-description />
      </div>
    </div>
  `,
  lotBiddingTemplate: `
    <div class="ta-lot-bidding d-flex flex-column">
      <div class="ta-lot-bidding-timer d-flex align-items-center justify-content-between">
        <lot-countdown-timer :lot="lot"/>
        <watchlist-button :lot="lot"/>
      </div>
      <div class="ta-lot-bidding-content flex-grow-1">
        <lot-collection-information :lot="lot"/>
        <bidding-latest-notification/>
        <bidding-reserve-not-met/>
        <hr>
        <bidding-start-price/>
        <bidding-actions>
          <bidding-current-bid/>
          <bidding-input/>
          <bidding-max-bid/>
          <bidding-buy-now-button/>
        </bidding-actions>
        <login-signup-buttons/>
        <hr>
        <private-treaty-actions/>
        <LatestBids/>
        <div v-if="mainConfig.descriptionInSidebar">
          <lot-view-description/>
        </div>
        <div v-if="mainConfig.sharesInSidebar">
          <hr>
          <SocialShareIcons :lot="lot"/>
        </div>
        <bidding-additional-links/>
      </div>
    </div>
  `,
  homePageTemplate: `
    <home-departments />

    <lot-list-items />
  `,
  signupInputsTemplate: `
    <fieldset class="mb-4">
      <legend>
        {{ $t("Account Details") }}
      </legend>
      <input-signup-email v-model="model.email" :errors="errors.email" />
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <input-signup-password v-model="model.password" :errors="errors.password"></input-signup-password>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <input-signup-password-confirmation v-model="model.password_confirmation" :errors="errors.password_confirmation"></input-signup-password-confirmation>
        </div>
      </div>
    </fieldset>

    <fieldset class="mb-4">
      <legend>
        {{ $t("Profile information") }}
      </legend>

      <div class="row">
        <div class="col-12 col-md-6 col-lg-4 mb-3">
          <input-signup-first-name v-model="model.first_name" :errors="errors.first_name"></input-signup-first-name>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-3">
          <input-signup-middle-name v-model="model.middle_name" :errors="errors.middle_name"></input-signup-middle-name>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-3">
          <input-signup-last-name v-model="model.last_name" :errors="errors.last_name"></input-signup-last-name>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <input-signup-title v-model="model.title" :errors="errors.title"></input-signup-title>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <input-signup-personal-id v-model="model.personal_id" :errors="errors.personal_id"></input-signup-personal-id>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <input-signup-company-name v-model="model.company_name" :errors="errors.company_name"></input-signup-company-name>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <input-signup-phone v-model="model.phone" :errors="errors.phone"></input-signup-phone>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <input-signup-is-company v-model="model.is_company"></input-signup-is-company>
        </div>
      </div>
    </fieldset>

    <fieldset class="mb-4">
      <legend>
        {{ $t("Billing Address") }}
      </legend>
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <input-signup-address1 v-model="model.billing_address.address_1" :errors="errors.addresses[0][0][0].address_1" type="billing" />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <input-signup-address2 v-model="model.billing_address.address_2" :errors="errors.addresses[0][0][0].address_2" type="billing" />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <input-signup-country v-model="model.billing_address.country_code" :errors="errors.addresses[0][0][0].country_code" type="billing" />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <input-signup-state v-model="model.billing_address.state" :errors="errors.addresses[0][0][0].state" type="billing" />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <input-signup-city v-model="model.billing_address.city" :errors="errors.addresses[0][0][0].city" type="billing" />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <input-signup-zip-code v-model="model.billing_address.zip_code" :errors="errors.addresses[0][0][0].zip_code" type="billing" />
        </div>
      </div>
    </fieldset>

    <fieldset class="mb-4">
      <div class="d-flex justify-content-between align-items-center">
        <legend>
          {{ $t("Shipping Address") }}
        </legend>
        <input-signup-same-as-billing-address v-model="sameAsBilling" />
      </div>

      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <input-signup-address1 v-model="model.shipping_address.address_1" :errors="errors.addresses[0][1][0].address_1" type="shipping" />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <input-signup-address2 v-model="model.shipping_address.address_2" :errors="errors.addresses[0][1][0].address_2" type="shipping" />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <input-signup-country v-model="model.shipping_address.country_code" :errors="errors.addresses[0][1][0].country_code" type="shipping" />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <input-signup-state v-model="model.shipping_address.state" :errors="errors.addresses[0][1][0].state" type="shipping" />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <input-signup-city v-model="model.shipping_address.city" :errors="errors.addresses[0][1][0].city" type="shipping" />
        </div>
        <div class="col-12 col-md-6 mb-3">
          <input-signup-zip-code v-model="model.shipping_address.zip_code" :errors="errors.addresses[0][1][0].zip_code" type="shipping" />
        </div>
      </div>
    </fieldset>

    <fieldset class="mb-4">
      <div class="d-flex justify-content-between align-items-center">
        <legend>
          {{ $t("Bank Account") }}
          <b-icon-trash
            v-if="bankAccountFilled"
            @click="eraseBankAccount"
            style="font-size: 1.3rem; color: red"
          ></b-icon-trash>
        </legend>
      </div>

      <div class="row">
        <div class="col-md-4">
          <input-signup-bank-account-beneficiary
            v-model="model.bank_account.account_name"
            :errors="errors.bank_account.account_name"
            :bankAccountFilled="bankAccountFilled"
          />
        </div>
        <div class="col-md-4">
          <input-signup-bank-account-sort-code
            v-model="model.bank_account.sort_code"
            :errors="errors.bank_account.sort_code"
            :bankAccountFilled="bankAccountFilled"
          />
        </div>
        <div class="col-md-4">
          <input-signup-bank-account-number
            v-model="model.bank_account.account_number"
            :errors="errors.bank_account.account_number"
            :bankAccountFilled="bankAccountFilled"
          />
        </div>
        <div class="col-md-4">
          <input-signup-bank-account-iban
            v-model="model.bank_account.iban"
            :errors="errors.bank_account.iban"
          />
        </div>
        <div class="col-md-4">
          <input-signup-bank-account-swift
            v-model="model.bank_account.swift"
            :errors="errors.bank_account.swift"
          />
        </div>
        <div class="col-md-4">
          <input-signup-bank-account-bank-name
            v-model="model.bank_account.bank_name"
            :errors="errors.bank_account.bank_name"
            :bankAccountFilled="bankAccountFilled"
          />
        </div>
        <div class="col-md-4">
          <input-signup-bank-account-address1
            v-model="model.bank_account.address"
            :errors="errors.bank_account.address"
          />
        </div>
        <div class="col-md-4">
          <input-signup-bank-account-address2
            v-model="model.bank_account.address_2"
            :errors="errors.bank_account.address_2"
          />
        </div>
        <div class="col-md-4">
          <input-signup-bank-account-address3
            v-model="model.bank_account.address_3"
            :errors="errors.bank_account.address_3"
          />
        </div>
      </div>
    </fieldset>
  `,

  auctionListItemTemplate: `
    <auction-image :auction="auction"/>
    <div class="ta-auction-list-item-desc">
      <div class="ta-auction-no-info d-flex justify-content-between">
        <auction-no :auction="auction"/>
        <number-of-lots :auction="auction"/>
      </div>
      <auction-title :auction="auction"/>
      <div class="ta-auction-list-item-dates">
        {{ auction.start_date | datetime }} - {{ auction.end_date | datetime }}
      </div>
    </div>
    <div class="ta-auction-list-item-buttons">
      <auction-add-to-watchlist :auction="auction"/>
      <auction-countdown-timer :auction="auction" />
    </div>
  `,
  favoriteAuctionListItemTemplate: `
    <auction-image :auction="auction"/>
    <div class="ta-auction-list-item-desc">
      <div class="ta-auction-no-info d-flex justify-content-between">
        <auction-no :auction="auction"/>
        <number-of-lots :auction="auction"/>
      </div>
      <auction-title :auction="auction"/>
      <div class="ta-auction-list-item-dates">
        {{ auction.start_date | datetime }} - {{ auction.end_date | datetime }}
      </div>
    </div>
    <div class="ta-auction-list-item-buttons">
      <auction-add-to-watchlist :auction="auction"/>
      <auction-countdown-timer :auction="auction" />
    </div>
  `,
  favoriteAuctionListPageTemplate: `
    <div class="ta-auction-section">
      <div class="ta-auction-list-header-top">
        <auction-keyword-search />
      </div>
      <hr class="mt-0">
      <div class="ta-auction-list-header ">
        <per-page-select />
        <div class="ta-auction-search-wrapper mb-3">
          <sorting-dropdown/>
          <switch-view/>
        </div>
      </div>
      <favorite-auction-list-items />
      <div class="ta-auction-list-footer">
        <pagination-summary/>
        <pagination-buttons/>
      </div>
    </div>
  `,
  auctionListPageTemplate: `
    <auction-list-filters />
    <div class="ta-auction-section">
      <div class="ta-auction-list-header-top">
        <auction-keyword-search />
      </div>
      <hr class="mt-0">
      <div class="ta-auction-list-header ">
        <per-page-select />
        <div class="ta-auction-search-wrapper mb-3">
          <sorting-dropdown/>
          <switch-view/>
        </div>
      </div>
      <auction-list-items />
    </div>
  `,
  auctionFiltersTemplate: `
    <filter-auction-status />
    <filter-department-list />
  `,
  auctionViewPageTemplate: `
    <auction-info/>
    <lot-list-and-filters>
        <lot-list-filters/>
        <div class="ta-lot-section">
            <div class="ta-lot-list-header-top">
                <lot-keyword-search/>
                <jump-to-lot/>
            </div>
            <hr class="mt-0">
            <div class="ta-lot-list-header ">
                <lot-list-per-page-select/>
                <div class="ta-lot-search-wrapper mb-3">
                    <sorting-dropdown/>
                    <lot-list-switch-view/>
                    <lot-list-filter-mobile-button/>
                </div>
            </div>
            <lot-list-items/>
        </div>
    </lot-list-and-filters>
  `,
  placeBidModalTemplate: `
    <ModalLotDetails :lot="lot"/>

    <div class="ta-lot-bidding lot-bidding-layout-grid">
      <lot-view-lot-no :label="$t('Lot Number')"/>
      <lot-countdown-timer :lot="lot"/>
      <bidding-start-price/>
      <div class="mb-2">
        <label class="muted-label">{{ $t('Current Bid') }}</label>
        <LotActualPrice :lot="lot"/>
      </div>
      <div class="mb-2">
        <label class="muted-label">{{ $t('Buy now') }}</label>
        <div class="text-black-600">{{ lot.buy_now_price || '(Empty)' }}</div>
      </div>
      <div class="mb-2">
        <label class="muted-label">{{ $t('No of bids') }}</label>
        <LotNumberOfBids :lot="lot"/>
      </div>
    </div>

    <bidding-actions>
      <div class="ta-lot-bidding-max-bid-wrapper">
        <bidding-input :label="true" class="mb-3" :for-mobile="true"/>
      </div>
      <bidding-max-bid-input/>
      <bidding-buy-now-button/>
    </bidding-actions>
  `
}

const DEFAULT_CONFIG = {
  /**
   * Your unique Client ID
   */
  clientId: null,

  /**
   * The language in which the UI should be displayed
   */
  lang: "en",

  /**
   * The default page that should be displayed when opening online auctions page
   * and bidding app starts working.
   *
   * Available options
   * /home
   * /auctions
   * /lots
   * /private-treaty/auctions
   * /private-treaty/lots
   * /favorite-auctions
   * /favorite-lots
   * /invoices
   * /settlements
   * /items
   * /temporary-items
   * /profile
   */
  defaultPage: "/home",

  /**
   * The URL on which user will be redirected when clicking on login buttons/links
   */
  loginUrl: null,

  /**
   * Javascript function which will be executed when login button is clicked.
   * You must provide either `loginUrl` or `loginHandler`
   * If you provide both both will work.
   */
  loginHandler: null,

  /**
   * The URL on which user will be redirected when clicking on signup buttons/links
   */
  signupUrl: null,

  /**
   * Javascript function which will be executed when signup button is clicked.
   * You must provide either `signupUrl` or `signupHandler`
   * If you provide both both will work.
   */
  signupHandler: null,

  /**
   * Links which will be displayed bellow bidding section.
   * (Ex: Terms and Conditions, Bidding Instructions, Shipping Details)
   * Texts specified must be in the configured language
   * Each link must have   -  `text` && (url || handler)`
   */
  links: [],

  /**
   * The URL which must return translations on all languages.
   * If you plan to have only English language you can skip this or set it to an empty string.
   * Response Example:
   * {
   *   "en": {
   *     "Buy now": "Buy now",
   *     "Place a Bid": "Place a Bid"
   *   },
   *   "de": {
   *     "Buy now": "Kaufe jetzt",
   *     "Place a Bid": "Ein Gebot abgeben"
   *   }
   * }
   */
  translationsUrl: null,

  /**
   * Translations object
   *
   * Example:
   * {
   *   "en": {
   *     "Buy now": "Buy now",
   *     "Place a Bid": "Place a Bid"
   *   },
   *   "de": {
   *     "Buy now": "Kaufe jetzt",
   *     "Place a Bid": "Ein Gebot abgeben"
   *   }
   * }
   */
  translations: {},

  /**
   * NOT FULLY IMPLEMENTED
   * ---------------------
   * Which social share icons to be displayed.
   * This can be either boolean or array
   * boolean true   - All supported social sharing buttons will be displayed
   * boolean false  - None of the social sharing buttons will be displayed
   * array          - Only those buttons will be displayed
   * Available social sharing buttons: 'email', 'facebook', 'twitter', 'linkedin'
   *
   * Example: `socialShares: ['facebook', 'email', 'twitter']`
   */
  socialShares: false,

  /**
   * To show print button or not.
   * This can be either boolean or function
   * boolean true    - The button will be displayed and default printing behavior will happen
   * boolean false   - The button will be hidden
   * Function        - The button will be displayed and specified function will
   *                    be called
   */
  printButton: false,

  /**
   * NOT FULLY IMPLEMENTED
   * ---------------------
   * Whether to show "Jump to lot" input or not
   */
  jumpToLot: true,

  /**
   * Whether to show breadcrumbs or not.
   */
  showBreadcrumbs: true,

  /**
   * Whether to show "Previous", "Next" and "Back to list" buttons or not
   */
  showPrevNextList: true,

  /**
   * Boolean - To show latest 5 bids in lot view or not.
   */
  showLatestBids: false,

  /**
   * Whether to enable "Add to watchlist" button or not
   */
  enableWatchlist: true,

  /**
   * Whether to show select box for page size or not
   */
  perPageSelect: true,

  /**
   * Whether to show authentication buttons or not
   */
  enableAuth: true,

  /**
   * Whether to enable bidding or not
   */
  enableBidding: true,

  /**
   * Whether to enable sidebar filters or not
   */
  enableFilters: true,

  /**
   * Whether to enable sorting options dropdown
   */
  enableSorting: true,

  /**
   * Whether to show countdown timer or not
   */
  showCountdown: true,

  /**
   * Whether to show description or lot in sidebar or not.
   * If you set it to false it will be displayed bellow images
   */
  descriptionInSidebar: false,

  /**
   * Whether to move share and print buttons in sidebar
   * If you set it to false they will be displayed bellow images
   */
  sharesInSidebar: false,

  /**
   * CSS Selector.
   * These elements will be visible when user is guest, but hidden when user is authorized
   */
  visibleForGuestsSelector: '.ta-visible-for-guest',

  /**
   * CSS Selector.
   * These elements will be visible when user is authorized, but hidden when user is guest
   */
  visibleForAuthSelector: '.ta-visible-for-auth',

  /**
   * Artisio SSO cookie name
   */
  artisioCookieName: 'artisio_timed_sso',

  /**
   * Whether to enable private treaty buttons like: "Make an offer" and "Make an Enquiry"
   */
  enablePrivateTreaty: false,

  /**
   * The email address which should be used for "Make an Enquiry" button for "mailto"
   */
  makeAnEnquiryEmail: '',

  /**
   * Artisio branch uuid for which you want to display content on website.
   * If you leave this null it will display content for all branches
   */
  branchUuid: null,

  /**
   * Whether to show collection information in lot listing an in lot inner page
   */
  showCollectionInfo: true,

  /**
   * Dynamic fields which must be rendered for lot description
   * Giving false here means that item's actual description should be rendered
   * Giving true here means that all dynamic fields should be iterated and rendered
   * In array you should give Artisio dynamic field names which will be used to render fields
   *
   * @type Array|Boolean
   */
  descriptionDynamicFields: false,


  showMoreInDescription: false,

  /**
   * Hide department list from sidebar if department is selected
   */
  hideDepartmentsIfSelected: false,

  templates: {
    ...TEMPLATES
  },

  /**
   * The type how the pagination should be handled
   * Options: 'PAGINATION', 'LOAD_MORE', 'SCROLL'
   */
  paginationType: 'PAGINATION',

  /**
   * The default sort field for lots
   * Any valid lot field is accepted.
   * Ex: lot_no, end_date, start_date, visited
   */
  lotDefaultSortField: 'end_date',

  /**
   * The default sort field for favorite lots
   * Any valid lot field is accepted.
   * Ex: lot_no, end_date, start_date, visited
   */
  favoriteLotDefaultSortField: 'end_date',

  /**
   * The default sort by for lots
   * Valid Options: asc, desc
   */
  lotDefaultSortBy: 'asc',

  /**
   * The default sort by for favorite lots
   * Valid Options: asc, desc
   */
  favoriteLotDefaultSortBy: 'asc',

  /**
   * The default sort field for auctions
   * Any valid auction field is accepted.
   * Ex: lot_no, end_date, start_date, visited
   */
  auctionDefaultSortField: 'end_date',

  /**
   * The default sort field for favorite auctions
   * Any valid auction field is accepted.
   * Ex: lot_no, end_date, start_date, visited
   */
  favoriteAuctionDefaultSortField: 'end_date',

  /**
   * The default sort by for auctions
   * Valid Options: asc, desc
   */
  auctionDefaultSortBy: 'asc',

  /**
   * The default sort by for favorite auctions
   * Valid Options: asc, desc
   */
  favoriteAuctionDefaultSortBy: 'asc',

  /**
   * Default limit for lot pagination
   * Options: 5, 10, 25, 50, 100
   */
  lotPaginationLimit: 25,

  /**
   * Default limit for favorite lots pagination
   * Options: 5, 10, 25, 50, 100
   */
  favoriteLotPaginationLimit: 25,

  /**
   * Default limit for auction pagination
   * Options: 5, 10, 25, 50, 100
   */
  auctionPaginationLimit: 25,

  /**
   * Default limit for favorite auctions pagination
   * Options: 5, 10, 25, 50, 100
   */
  favoriteAuctionPaginationLimit: 25,

  /**
   * Terms and Conditions HTML string.
   * If set to false terms and conditions links will be hidden
   */
  termsAndConditionsHtml: false,

  /**
   * Currency formatting special string.
   * {symbol} can be moved at the end of the formatting string or replaced with {code} to display currency code, instead of symbol
   * Whitespace can be inserted between {symbol} and {number:} to have visual separation on webapp
   * In {number:} "," acts as thousands separator. Following options are supported for a thousand separator: {
   *   Dot - "."
   *   Comma - ","
   *   Apostrophe - "‘"
   *   Space - " "
   * }
   * In {number:} "." acts as decimal separator. Following options are supported for a decimal separator: {
   *   Dot - "."
   *   Comma - ","
   * }
   * Example:
   * "{symbol}{number:000 000,00}" - $100 000,99
   * "{symbol}{number:000,000.00}" - $100,000.99
   * "{symbol}{number:000000,00}" - $100000,99
   * "{symbol}{number:000'000,00}" - $100'000,99
   * "{code} {number:000 000,00}" - USD 100 000,99
   * "{number:000 000,00} {code}" - 100 000,99 USD
   * "{number:000 000,00}{code}" - 100 000,99USD
   * "{number:000 000.00} {code}" - 100 000.99 USD
   * "{number:000 000.00}{code}" - 100 000.99USD
   * "€{number:000 000,00}" - €100 000,99
   */
  currencyFormat: '{symbol}{number:000,000.00}',

  dateFormat: 'DD MMM YYYY',

  datetimeFormat: 'DD MMM YYYY HH:mm',

  datetimeFormatWithSeconds: 'DD MMM YYYY HH:mm:ss',

  /**
   * When you change something in filters section whether it should auto apply filters or not.
   * If this is set to false "Apply Filters" button will be visible
   */
  autoApplyFilters: true,

  fullScreenButtonOnImage: false,

  dontOpenOnImageClick: false,

  backButtonOnImage: false,

  /**
   * Whether to enable "Add to watchlist" button or not
   */
  enableNotifyMeOnLotStart: false,

  /**
   * The home page URL of the application.
   * If you leave this null it will use Webapp's default home page address
   */
  homeUrl: null
};
const USER_CONFIG = {};
const STRING_OPTIONS = [
  "clientId",
  "lang",
  "defaultPage",
  "loginUrl",
  "signupUrl",
  "translationsUrl",
  "visibleForGuestsSelector",
  "visibleForAuthSelector",
  "artisioCookieName",
  "branchUuid",
  "paginationType",
  "lotDefaultSortField",
  "lotDefaultSortBy",
  "auctionDefaultSortField",
  "auctionDefaultSortBy",
  "lotPaginationLimit",
  "auctionPaginationLimit",
  'termsAndConditionsHtml',
  "currencyFormat",
  "dateFormat",
  "timeFormat",
  "timeFormatWithSeconds",
  "datetimeFormat",
  "datetimeFormatWithSeconds",
  "makeAnEnquiryEmail",
  "homeUrl"
];
const BOOLEAN_OPTIONS = [
  "socialShares",
  "jumpToLot",
  "showBreadcrumbs",
  "showPrevNextList",
  "showLatestBids",
  "enableWatchlist",
  "perPageSelect",
  "enableAuth",
  "enableBidding",
  "enableFilters",
  "enableSorting",
  "showCountdown",
  "descriptionInSidebar",
  "showMoreInDescription",
  "sharesInSidebar",
  "enablePrivateTreaty",
  "showCollectionInfo",
  "hideDepartmentsIfSelected",
  "autoApplyFilters",
  "fullScreenButtonOnImage",
  "dontOpenOnImageClick",
  "backButtonOnImage",
  "enableNotifyMeOnLotStart"
];
const el = document.querySelector("#artisioTimedAuctions");
let dataset = {};
if (!el) {
  console.error(`Artisio Webapp HTML Element with id "artisioTimedAuctions" does not exist`);
} else {
  dataset = {
    ...el.dataset
  };
}

// Prepare boolean variables
for (const field of BOOLEAN_OPTIONS) {
  if (dataset[field] !== undefined) {
    dataset[field] = ["true", "1"].includes(dataset[field].toLowerCase());
  }
}

// Merge properties taken from div data attributes with JavaScript
dataset = {
  ...dataset,
  ...window.artisioWebApp
}
for (let field of [...BOOLEAN_OPTIONS, ...STRING_OPTIONS]) {
  if (dataset[field] !== undefined) {
    USER_CONFIG[field] = dataset[field];
  }
}

if (dataset.links !== undefined) {
  try {
    if (typeof dataset.links === 'string') {
      USER_CONFIG.links = JSON.parse(dataset.links);
      USER_CONFIG.links.forEach((link) => (link.handler = convertHandlerToFn(link.handler)));
    } else if (typeof dataset.links === 'object' && dataset.links.length !== undefined) {
      USER_CONFIG.links = dataset.links;
    } else {
      console.error(`Invalid value for "links". It must be array or JSON`)
    }
  } catch (e) {
    console.error(`"links" is not valid JSON`);
  }
}
if (dataset.loginHandler) {
  USER_CONFIG.loginHandler = convertHandlerToFn(dataset.loginHandler);
}

if (dataset.signupHandler) {
  USER_CONFIG.signupHandler = convertHandlerToFn(dataset.signupHandler);
}
if (dataset.printButton !== undefined) {
  if (typeof dataset.printButton === 'boolean') {
    USER_CONFIG.printButton = dataset.printButton;
  } else {
    if (["true", "1"].includes(dataset.printButton.toLowerCase())) {
      USER_CONFIG.printButton = true;
    } else if (["false", "0"].includes(dataset.printButton.toLowerCase())) {
      USER_CONFIG.printButton = false;
    } else {
      USER_CONFIG.printButton = convertHandlerToFn(dataset.printButton);
    }
  }
}

if (dataset.descriptionDynamicFields !== undefined) {
  if (typeof dataset.descriptionDynamicFields === 'boolean') {
    USER_CONFIG.descriptionDynamicFields = dataset.descriptionDynamicFields;
  } else {
    if (typeof dataset.descriptionDynamicFields === 'string') {
      if (["true", "1"].includes(dataset.descriptionDynamicFields.toLowerCase())) {
        USER_CONFIG.descriptionDynamicFields = true;
      } else if (["false", "0"].includes(dataset.descriptionDynamicFields.toLowerCase())) {
        USER_CONFIG.descriptionDynamicFields = false;
      }
    } else {
      USER_CONFIG.descriptionDynamicFields = dataset.descriptionDynamicFields;
    }
  }
}

if (dataset.translations) {
  USER_CONFIG.translations = dataset.translations;
}

if (dataset.templates) {
  USER_CONFIG.templates = dataset.templates;
}

/**
 * Converts global handler string into function
 *
 * @param handlerStr
 * @returns {Function}
 */
function convertHandlerToFn(handlerStr) {
  if (typeof handlerStr === "function") {
    return handlerStr;
  }
  const parts = handlerStr.split(".");
  let fn = window;
  for (let part of parts) {
    fn = fn[part];
    if (!fn) {
      throw new Error(`"${handlerStr}" is not a valid function`);
    }
  }
  if (typeof fn !== "function") {
    throw new Error(`"${handlerStr}" is not a valid function`);
  }
  return fn;
}

let CONFIG = {};
mergeDeep(CONFIG, DEFAULT_CONFIG, USER_CONFIG)

if (!CONFIG.clientId) {
  throw new Error('Please provide "clientId"');
}

if (!(typeof CONFIG.translations === 'object')) {
  throw new Error(`"translations" config must be object`)
}

export default CONFIG;
console.log(DEFAULT_CONFIG, USER_CONFIG);
export {DEFAULT_CONFIG, USER_CONFIG};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, {[key]: {}});
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, {[key]: source[key]});
      }
    }
  }

  return mergeDeep(target, ...sources);
}
