<template>
  <InputWidget
    v-model="model"
    :error="errors"
    :id="id"
    type="text"
    @change="abstractChange"
    :label="computedLabel"
    :placeholder="computedPlaceholder"
    :rules="rules"
  >
  </InputWidget>
</template>

<script>
import BaseInputMixin from "../../../../mixins/baseInputMixin";
import InputWidget from "../../../core/InputWidget";

export default {
  name: "AbstractSignupTextInput",
  components: {InputWidget},
  mixins: [BaseInputMixin],
  props: {
    id: {
      required: true,
      type: String
    },
    rules: {
      required: false,
      type: String,
      default: ""
    }
  },
  methods: {
    abstractChange() {
      this.inputChange();
      this.$emit('change');
    }
  }
}
</script>

<style scoped>

</style>
