import { render, staticRenderFns } from "./InputSignupLastName.vue?vue&type=template&id=6845f864&scoped=true&"
import script from "./InputSignupLastName.vue?vue&type=script&lang=js&"
export * from "./InputSignupLastName.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6845f864",
  null
  
)

export default component.exports