<template>
  <div>
    <div class="ta-buy-now-button">
      <div class="ta-horizontal-or">
        {{ $t('or') }}
      </div>
      <div id="buy-now-button-wrapper" v-b-tooltip.hover>
        <button
          id="buy-now-button"
          :disabled="disabled"
          @click="onBuyNowButtonClick($event)"
          class="btn btn-primary btn-block d-flex justify-content-between align-items-center mt-2">
          {{ $t('Buy now') }}
          <span>
                {{ lot.buy_now_price | asCurrency(lot.currency.code) }}
              </span>
        </button>
      </div>
      <b-tooltip v-if="disabled" container="artisioTimedAuctions" target="buy-now-button">
        <template v-if="!is_authorized">
          {{ $t('Please login to make a bid') }}
        </template>
        <template v-else-if="!biddingStarted">
          {{ $t('Lot is not open yet') }}
        </template>
        <template v-else-if="biddingFinished">
          {{ $t('Lot has ended') }}
        </template>
      </b-tooltip>
    </div>

    <!-- Buy now Confirmation -->
    <buy-now-confirmation :show.sync="showBuyNowConfirmation"
                          target="buy-now-button"
                          @confirm="$emit('confirm', true)"/>
    <!--/ Buy now Confirmation -->
  </div>
</template>

<script>
import BuyNowConfirmation from "../BuyNowConfirmation";
import {mapState} from "vuex";

export default {
  name: "BuyNowButton",
  components: {BuyNowConfirmation},
  props: ['lot', 'disabled', 'biddingStarted', 'biddingFinished'],
  data: () => ({
    showBuyNowConfirmation: false
  }),
  computed: {
    ...mapState(['is_authorized'])
  },
  methods: {
    onBuyNowButtonClick() {
      this.showBuyNowConfirmation = !this.showBuyNowConfirmation;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../bootstrap/import";

#artisioTimedAuctions {
  .ta-buy-now-button {
    .btn {
      margin-top: 2rem;
      font-size: 1.25rem;

      > span {
        font-size: 1.5rem;
      }
    }
  }


  .ta-horizontal-or {
    position: relative;
    width: 100%;
    text-align: center;
    font-weight: 500;
    color: $gray-700;
    margin-top: 0;
    //font-size: $font-size-lg;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: 2px;
      height: 2px;
      width: calc((#{100%} - #{40px}) / 2);
      background-color: white;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }
}
</style>
