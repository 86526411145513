<script>
import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
import Carousel from "../components/Carousel";
import GallerySlideshow from '../plugins/vue-gallery-slideshow/src/component/GallerySlideshow';
import LotViewBidding from "../components/configuration/lot-view/LotViewBidding";
import LotInfo from "../components/LotInfo";
import SocialShareIcons from "../components/lot-view/SocialShareIcons";
import LotViewSkeleton from "../components/lot-view/LotViewSkeleton"
import PageComponent from "../components/core/PageComponent";
import LatestBidsModal from "../components/lot-view/LatestBidsModal";
import SocialSharePopup from "@/components/lot-view/sidebar/SocialSharePopup";
import WatchlistButton from "@/components/lot-view/sidebar/WatchlistButton";
import WatchlistCount from "@/components/lot-view/sidebar/WatchlistCount";
import logger from "../logger";
import templatable from "@/mixins/templatable";
import PlaceBidModal from "@/components/lot-view/PlaceBidModal";
import TriggerBidBuyModalButton from "@/components/lot-view/TriggerBidBuyModalButton";
import dynamicFieldsMixin from "@/mixins/dynamicFieldsMixin";

export default {
  name: "LotView",

  components: {
    PageComponent,
    LotViewSkeleton,
    SocialShareIcons,
    LotInfo,
    LotViewBidding,
    Carousel,
    GallerySlideshow,
    LatestBidsModal,
    WatchlistButton,
    WatchlistCount,
    SocialSharePopup,
    PlaceBidModal,
    TriggerBidBuyModalButton
  },
  mixins: [templatable('lotViewTemplate'), dynamicFieldsMixin],
  data: () => ({
    template: `
      <page-component class="ta-lot-view">
        <div v-if="lotObject.loading || !auctionObject.data.increments">
          <lot-view-skeleton/>
        </div>
        <template v-else>
          [[template]]
        </template>
        <LatestBidsModal />
        <PlaceBidModal />
      </page-component>`
  }),
  computed: {
    ...mapState(['lotObject', 'auctionObject', 'mainConfig']),
    ...mapState({
      lot: state => state.lotObject.data,
      loginFinished: state => state.socket.loginFinished
    }),
    ...mapGetters(['is_authorized', 'isOnline']),
  },
  watch: {
    loginFinished() {
      if (this.lot.is_dynamic) {
        this.getLotDataSocket([this.lot.uuid]);
      }
    },
    '$route.query': {
      immediate: true,
      async handler() {
        this.setLotFilters({
          ...this.$route.query
        });
        try {
          await this.getLotData(this.$route.params.lotno);
        } catch (e) {
          logger.error(e)
        }

        // await this.getAuctionData(this.lot.auction_uuid);

        const auction = this.auctionObject.data;
        await this.registerAsBidder(auction);
      },
    }
  },
  methods: {
    ...mapActions(['getLotData', 'getAuctionData', 'getCustomerMeta', 'registerAsBidder', 'getLotDataSocket']),
    ...mapMutations(['setLotObjectData', 'setLotFilters']),
    async retryToGetLotData() {
      let lotnoOrUuid = this.$route.params.lotno;
      try {
        await this.getLotData(lotnoOrUuid);
      } catch (e) {
        logger.error(e)
        setTimeout(() => this.getLotData(lotnoOrUuid), 1000)
      }
    }
  },
  beforeDestroy() {
    this.setLotObjectData({})
  },
  render(h, context) {
    if (!this.templateRender) {
      return h('div', 'Loading...')
    }
    setTimeout(() => {
      const html = document.querySelector('html')
      html.style.scrollBehavior = 'smooth';
      html.scrollTop = 0;
      setTimeout(() => html.style.scrollBehavior = '', 1000)
    }, 1000)
    return this.templateRender();
  },
}
</script>

<style scoped lang="scss">
@import "../bootstrap/import";
@import "../scss/mixins";

#artisioTimedAuctions {
  .ta-lot-breadcrumbs-wrapper {
    border-bottom: 2px solid $gray-200;
    margin-bottom: 1rem;
  }

  .ta-lot-breadcrumbs {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ta-jump-to-lot {
    width: 9rem;
  }

  .ta-lot-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ta-lot-title {
    font-size: 1.5rem;
    min-height: 40px;
    margin: 0;
  }

  @include media-breakpoint-down(sm) {
    .ta-lot-title-wrapper {
      flex-direction: column;
      align-items: start;
    }
    .ta-lot-title {
      font-size: 1.25rem;
    }
    .ta-lot-next-previous {
      border-top: 2px solid $gray-200;
      //margin-top: 1rem;
    }
    .ta-lot-next-previous {
      margin-bottom: 1rem;
    }

  }

  @include media-breakpoint-down(xs) {
    .ta-lot-breadcrumbs {
      flex-direction: column;
      align-items: start;
    }
    .ta-jump-to-lot {
      width: 100%;
      margin-bottom: 1rem;
    }
  }


  @include media-breakpoint-down(sm) {

    .ta-lot-view-content {
      padding: 0;
    }
  }
}

.ta-lot-view {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ta-lot-view-wrapper {
  flex: 1;
  overflow: visible;
}

#artisioTimedAuctions {
  .ta-lot-view-content {
    height: 100%;
    overflow: auto;
    @include scrollbars();
  }
}

.ta-lot-view-bidding {
  height: 100%;
  overflow: visible;
  @include scrollbars();
}

.ta-bidding-actions-buttons-on-mobile {
  display: none;
  padding: 1rem;
  gap: 1rem;
  //position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1020;
  height: 80px;

  .btn-bid-buy {
    flex: 1
  }
}

@include media-breakpoint-down(md) {
  .ta-bidding-actions-buttons-on-mobile {
    display: flex;
  }
}
</style>
