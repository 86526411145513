<template>
  <filter-group :title="$t('Auction Status')"
                id="collapse-auction-statuses"
                class="ta-filter-group"
  >
    <div v-for="(label, status) in auctionStatuses" :key="status">
      <b-form-radio name="auction-status" v-model="model" @change="filtersChanged" :value="status">
        {{ label }}
      </b-form-radio>
    </div>
  </filter-group>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {AUCTION_DEFAULT_STATUS, AUCTION_LIST_DEFAULT_PAGE} from "../../constants";
import {updateAuctionsRoute} from "../../helpers";
import FilterGroup from "@/components/FilterGroup";

export default {
  name: "FilterAuctionStatus",
  components: {FilterGroup},
  data: () => ({
    model: AUCTION_DEFAULT_STATUS,
    secondaryModel: null
  }),
  computed: {
    ...mapState(['auctionQueryParams']),
    ...mapGetters(['is_authorized', 'auctionStatuses'])
  },
  watch: {
    auctionQueryParams() {
      this.model = this.auctionQueryParams.status;
      this.secondaryModel = this.auctionQueryParams.sold_to_me;
    },
  },
  methods: {
    ...mapMutations(['setAuctionFilters']),
    filtersChanged() {
      this.setAuctionFilters({
        status: this.model,
        sold_to_me: this.secondaryModel,
        page: AUCTION_LIST_DEFAULT_PAGE,
      });
      updateAuctionsRoute();
    }
  },
  mounted() {
    this.model = this.auctionQueryParams.status ?? AUCTION_DEFAULT_STATUS;
    this.secondaryModel = this.auctionQueryParams.sold_to_me;
  }
}
</script>

<style scoped>

</style>
