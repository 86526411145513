<template>
  <div class="d-inline-block" v-if="auction.status !== 'complete'">
    <count-down-timer v-if="mainConfig.showCountdown && auction.start_date && auction.end_date"
                    :start_date="auction.start_date"
                    :end_date="auction.end_date"
                    :label="label"/>
  </div>
  <div v-else>
    <span v-if="auction.status === 'complete'" class="badge badge-secondary">{{ $t('Ended') }}</span>
  </div>
</template>

<script>
import {mapState} from "vuex";
import CountDownTimer from "@/components/CountDownTimer";

export default {
  name: "AuctionCountdownTimer",
  components: {CountDownTimer},
  props: {
    auction: {
      required: true,
      type: Object
    },
    label: {
      type: [Boolean],
      default: true
    }
  },
  computed: {
    ...mapState(['mainConfig'])
  }
}
</script>

<style scoped>

</style>
