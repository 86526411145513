<template>
  <div class="ta-app">
    <ata-msg-modal/>
    <ata-toast/>
    <router-view class="ta-app-router-view"/>
  </div>
</template>

<script>
import AtaToast from "./components/bootstrap/AtaToast";
import AtaMsgModal from "./components/bootstrap/AtaMsgModal";
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import Vue from "vue";
import {checkOnlineStatus, hideHTMLElements, showHTMLElements} from "./helpers";
import CONFIG from "./user-config";

export default {
  components: {
    AtaToast,
    AtaMsgModal
  },

  data: () => ({
    onlineStatusInterval: null,
  }),

  methods: {
    ...mapMutations(['mutateIsAuthorized', 'setIsOnline']),
    ...mapActions(['sendSocketLogin', 'getCustomerMeta', 'socketLoginCallback']),
    async checkAndSetOnlineStatus() {
      this.setIsOnline(await checkOnlineStatus());
    }
  },

  computed: {
    ...mapGetters(['is_authorized']),
    ...mapState(['user'])
  },

  async mounted() {
    // this.mutateIsAuthorized(Boolean(this.$cookie.get(CONFIG.artisioCookieName)));
    // this.getCustomerMeta();

    await this.checkAndSetOnlineStatus();
    this.onlineStatusInterval = setInterval(this.checkAndSetOnlineStatus, 5000)
  },
  destroyed() {
    clearInterval(this.onlineStatusInterval);
  }
}
</script>

<style lang="scss">
#artisioTimedAuctions {
  &,
  .ta-app,
  .ta-app-router-view {
    height: 100%;
  }
}

//#artisioTimedAuctions {
//  padding: 20px;
//}
//#artisioTimedAuctions hr {
//  border-top: 1px solid rgba(130, 130, 130, 0.1) !important;
//}
</style>
