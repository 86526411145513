<template>
  <div class="col-md-6 mb-2">
    <b-modal
      id="auction-terms-modal"
      ref="auctionTermsModal"
      :title="$t('Terms and Conditions')"
      :visible="displayModal"
      @hidden="displayModal = false"
      static
      lazy
    >

      <div v-html="mainConfig.termsAndConditionsHtml"></div>
    </b-modal>
    <link-button v-if="mainConfig.termsAndConditionsHtml" @click="displayModal = true" icon="file-earmark">
      <span class="ml-1 pr-5">{{ $t(`Terms and Conditions`) }}</span>
    </link-button>
  </div>
</template>

<script>
import LinkButton from "@/components/LinkButton";
import auctionViewMixin from "@/views/AuctionViewPage/auctionViewMixin";

export default {
  name: "AuctionTermsLink",
  components: {LinkButton},
  mixins: [auctionViewMixin],
  data: () => ({
    displayModal: false,
  }),
  methods: {
    showModal() {
      this.displayModal = true;
    }
  }
}
</script>

<style scoped>

</style>
