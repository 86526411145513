import VModelMixin from "./vModelMixin";

export default {
  mixins: [VModelMixin],

  data() {
    return {
      defaultLabel: null,
      defaultPlaceholder: null,
    }
  },

  props: {
    errors: {
      required: true
    },
    label: {
      required: false,
      default: function () {
        return this.defaultLabel
      }
    },
    placeholder: {
      required: false,
      default: function () {
        return this.defaultPlaceholder
      }
    }
  },
  computed: {
    computedLabel() {
      return this.label || this.defaultLabel
    },
    computedPlaceholder() {
      return this.placeholder || this.defaultPlaceholder
    }
  }
}
