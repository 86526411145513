import {mapActions, mapGetters, mapState, mapMutations} from "vuex";
import {BIDDING_TYPE_EXACT_UPCOMING_AMOUNT} from "../constants";

export default {
  data: () => ({
    showBuyNowConfirmation: false,
  }),
  computed: {
    ...mapGetters(['customerMeta', 'is_authorized', 'settings']),
    ...mapState({
      lot: state => state.lotObject.data,
      biddingStarted: state => state.lotObject.biddingStarted,
      biddingFinished: state => state.lotObject.biddingFinished,
      upcomingBidPopover: state => state.lotObject.upcomingBidPopover,
    }),
    currentBid() {
      return this.lot.winning_bid ? this.lot.winning_bid.amount : 0;
    },
    next() {
      const currentBid = parseFloat(parseFloat(this.currentBid).toFixed(2));

      if (!currentBid) {
        return this.lot.start_price;
      }

      let increments = [...this.lot.auction.increments];

      if (!increments) {
        return '';
      }
      increments = increments.sort((a, b) => parseFloat(a.up_to) - parseInt(b.up_to));
      let increment = increments.find(inc => inc.up_to && parseFloat(inc.up_to) > currentBid);
      if (!increment) {
        // Find last increment without up_to
        increment = increments.find(inc => !inc.up_to);
      }
      increment = parseFloat(increment.increment);
      return currentBid + increment;
    },
    isLotUnsold() {
      return this.lot.status === 'unsold'
    },
    showLatestBidNotification() {
      return this.biddingFinished || this.isLotUnsold || this.isLotSold || this.lot.has_my_bid
    },
    isLotSold() {
      return this.lot.status === 'sold'
    },
    lotStartDate() {
      return this.lot.actual_start_date;
    },
    lotEndDate() {
      return this.lot.actual_end_date;
    },
    iWonTheItem() {
      return this.isCurrentUserLastBidder && this.biddingFinished
    },
    isCurrentUserLastBidder() {
      if (this.customerMeta?.uuid && this.lot.winning_bid?.customer_uuid) {
        return this.customerMeta.uuid === this.lot.winning_bid.customer_uuid;
      }
      return false;
    },
    biddingDisabled() {
      return !this.is_authorized || !this.biddingStarted || this.biddingFinished
    },
    buyNowDisabled() {
      return !this.is_authorized ||
        !this.biddingStarted ||
        this.biddingFinished ||
        (this.lot.winning_bid && this.lot.buy_now_price && this.lot.winning_bid.amount >= this.lot.buy_now_price)
    },
    reserveMet() {
      return this.lot.winning_bid && (!this.lot.reserve || this.lot.reserve <= this.lot.winning_bid.amount)
    },
    /**
     * When reserve and winning_bid exists, but reserve is more than winning_bid.amount
     * @returns {null|*|boolean}
     */
    reserveNotMet() {
      return this.lot.reserve && this.lot.winning_bid && this.lot.reserve > this.lot.winning_bid.amount
    },
  },
  methods: {
    ...mapActions(['show401Notification', 'sendBidInSocket', 'validateUpcomingAmount']),
    ...mapMutations(['hideUpcomingAmountPopover']),
    async place_bid(buy_now = false) {
      if (!this.is_authorized) {
        this.show401Notification();
        return;
      }

      if (buy_now) {
        this.showBuyNowConfirmation = false;
      }

      if (this.settings.default_bidding_type === BIDDING_TYPE_EXACT_UPCOMING_AMOUNT && !buy_now) {
        const valid = await this.validateUpcomingAmount({
          newAmount: this.newBidAmount,
          currentAmount: this.lot.winning_bid?.amount,
          startPrice: this.lot.start_price,
          increments: this.lot.auction.increments,
          stateObjectName: "upcomingBidPopover",
        });

        if (!valid)
          return;
      }

      const payload = buy_now ? {
        'action': 'bid',
        'data': {
          'lot_uuid': this.lot.uuid,
          'amount': this.lot.buy_now_price,
          'buy_now': 1
        }
      } : {
        'action': 'bid',
        'data': {
          'lot_uuid': this.lot.uuid,
          'amount': this.newBidAmount,
        },
      }

      this.sendBidInSocket(payload);
    },
  }
}
