<template>
  <InputWidget
    v-model="model"
    :error="errors"
    id="input-billing_address_country"
    type="select"
    @change="inputChange"
    :label="computedLabel"
    :placeholder="computedPlaceholder"
    rules="required"
    :options="countryOptions"
  >
  </InputWidget>
</template>

<script>
import BaseSignupTextInput from "../core/AbstractSignupTextInput";
import AbstractSignupTextInput from "../core/AbstractSignupTextInput";
import BaseInputMixin from "../../../../mixins/baseInputMixin";
import {mapGetters} from "vuex";
import InputWidget from "../../../core/InputWidget";

export default {
  name: "InputSignupCountry",
  components: {InputWidget, AbstractSignupTextInput, BaseSignupTextInput},
  mixins: [BaseInputMixin],

  data() {
    return {
      defaultLabel: this.$t("Country",)
    }
  },

  computed: {
    ...mapGetters(['countryOptions'])
  }
}
</script>

<style scoped>

</style>
