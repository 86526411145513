import Vue from "vue";
import moment from "moment";
import i18n from "../i18n";
import {asCurrency} from "../filters/currency";
import store from "./index";
import CONFIG from "../user-config";
import {LOT_DEFAULT_FILTERS} from "./state";

export const setAuctionsLoading = (state, loading) => (state.auctions.loading = loading);

export const setAuctions = (state, auctions) => {
  state.auctions.results = auctions.result
  state.auctions.totalRows = auctions.count;
};
export const setAuctionObjectLoading = (state, loading) =>
  (state.auctionObject.loading = loading);
export const setAuctionObjectData = (state, data) => {
  // data.auction_location_info= {'en': 'Lorem ipsum'}
  // data.contact_info = {'en': 'Zura Sekhniashvili'}
  // data.viewing_info = {'en': 'Viewing is only allowed...'}
  // data.viewing_start_date = '2022-04-09 10:00'
  // data.viewing_end_date = '2022-04-10 10:00'
  state.auctionObject.data = data
};

export const mutateAuctionPagination = (state, pagination) => {
  state.auctions.pagination = pagination;
};
export const mutateAuctionFilters = (state, filters) => {
  state.auctions.filters = filters;
};
export const mutateTimeDiff = (state, server_time) => {
  state.timeDiff = moment.duration(moment(new Date()).diff(server_time));
};

export const setLotsLoading = (state, loading) => (state.lots.loading = loading);

export const emptyAuctions = (state) => {
  state.auctions.results = [];
  state.auctions.totalRows = 0;
};
export const emptyLots = (state) => {
  state.lots.results = [];
  state.lots.totalRows = 0;
};
export const setLots = (state, lots) => {
  state.lots.results = [...state.lots.results, ...lots.results.map((l) => {
    return setLotActualDates(l, state.timeDiff);
  })];
  state.lots.totalRows = lots.count;
};
export const setFavoriteLots = (state, lots) => {
  state.lots.results = lots.results.map((l) => {
    return setLotActualDates(l, state.timeDiff);
  });
  state.lots.totalRows = lots.count;
};
export const setLotObjectLoading = (state, loading) =>
  (state.lotObject.loading = loading);


export const setLotObjectData = (state, l) => {
  state.lotObject.data = setLotActualDates(l, state.timeDiff);
};

export const mutateLotObject = (state, {data, fetch}) => {
  // Get lot from lot list
  let lot = state.lots.results.find((lot) => lot.uuid === data.uuid);

  // If the updated lot is the lot which is opened at the moment we must updated opened lot details
  if (state.lotObject.data.uuid === data.uuid) {
    data = {
      ...setLotActualDates(data, state.timeDiff),
      hasBids: !!data.winning_bid,
    }
    if (typeof data.has_my_bid === 'undefined' && data.last_bids) {
      data.has_my_bid = data.last_bids.some(b => b.paddle_no === state.customer.customer_no)
    }

    // Get the winning bid information
    const winning_bid = data.winning_bid;

    // If this is not initial fetch request and winning bid customer is currently authorized customer
    const selfBid = !fetch && winning_bid?.customer_uuid === state.customer.uuid;

    // If I made the bid
    if (selfBid) {

      // If I have not made bid before
      if (!state.lotObject.data.has_my_bid) {
        state.lotObject.data.has_my_bid = true;
      }
      // show notification
      store.commit("showNotification", {
        title: i18n.t("New Bid!"),
        text: i18n.t("You have created a new bid with amount of {amount}", {
          amount: '<strong>' + asCurrency(winning_bid.amount,state.lotObject.data.currency.code) + '</strong>',
        }),
        variant: "success",
      });
    }

    state.lotObject.data = {
      ...state.lotObject.data,
      ...data,
    }
  }

  for (const key in data) {
    if (lot) {
      Vue.set(lot, key, data[key]);
    }
  }
};

export const mutateBidderStatus = (state, payload) => {
  let auction = state.auctions.results.filter(
    (auction) => auction.uuid === payload.auction_uuid
  )[0];
  let auctionObject =
    state.auctionObject.data.uuid === payload.auction_uuid
      ? state.auctionObject.data
      : null;

  if (auction) auction.is_approved_bidder = payload.bid_allowed;

  if (auctionObject) {
    auctionObject.is_approved_bidder = payload.bid_allowed;

    if (state.lotObject.data.auction_uuid === auctionObject.uuid) {
      state.lotObject.data.max_bid = null;
    }
  }
};

export const mutateLotMaxBid = (state, max_bid) => {
  state.lotObject.data.max_bid = max_bid;
};
export const deleteLotMaxBid = (state) => {
  state.lotObject.data.max_bid = null;
};
export const mutateLotFilters = (state, filters) => {
  state.lots.filters = filters;
};
export const mutateCustomerMeta = (state, data) => {
  state.customer = data;
};
export const showNotification = (state, data) => {
  data["visible"] = true;
  data["duration"] = data["duration"] ?? 2000;
  state.toast = data;
};
export const showModalMsgBox = (state, data) => {
  data["visible"] = true;
  data["headerBgVariant"] = data["headerBgVariant"] ?? "danger";
  data["headerTextVariant"] = data["headerTextVariant"] ?? "light";
  state.modalMsgBox = data;
};
export const mutateIsAuthorized = (state, is_authorized) => {
  state.is_authorized = is_authorized;
};
export const mainConfig = (state, config) => {
  state.mainConfig = {
    ...state.mainConfig,
    ...config,
  };
};
export const fireSignal = (state) => {
  state.signal = Number(!state.signal);
};
export const changeLotView = (state, viewType) => {
  state.lotListViewType = viewType;
  localStorage.setItem("ARTISIO_LOT_LIST_VIEW_TYPE", viewType);
};
export const changeAuctionView = (state, viewType) => {
  state.auctionListViewType = viewType;
  localStorage.setItem("ARTISIO_AUCTION_LIST_VIEW_TYPE", viewType);
};
export const markDoNotShowConfirmOnBid = (state, lot) => {
  if (!state.doNotShowConfirmOnBid) {
    state.doNotShowConfirmOnBid = {};
  }
  state.doNotShowConfirmOnBid[lot] = true;
  localStorage.setItem(
    "ARTISIO_DO_NOT_SHOW_CONFIRM_ON_BID",
    JSON.stringify(state.doNotShowConfirmOnBid)
  );
};
export const setHasMyBid = (state) => {
  state.lotObject.data.has_my_bid = true;
};
export const SOCKET_ONOPEN = (state, event) => {
  Vue.prototype.$socket = event.currentTarget
  state.socket.isOpened = true;
  state.socket.disconnectTime = null;
  store.dispatch('sendSocketLogin')
  console.log("SOCKET_ONOPEN ");
};
export const SOCKET_ONERROR = (state, arg) => {
  console.log("SOCKET_ONERROR ", arg);
};
export const SOCKET_ONCLOSE = (state, arg) => {
  state.socket.isOpened = false;
  state.socket.loginFinished = false;
  if (state.socket.disconnectTime === null) {
    state.socket.disconnectTime = Date.now();
  }
  console.log("SOCKET_ONCLOSE ", arg);
};
export const SOCKET_RECONNECT = (state, arg) => {
  console.log("SOCKET_RECONNECT ", arg);
};
export const SOCKET_ONMESSAGE = (state, arg) => {
  console.log("SOCKET_ONMESSAGE ", arg);
};

export const setCurrentUser = (state, data) => {
  const expires = {expires: data.remember ? data.expires_at : "session"}
  Vue.cookie.set(CONFIG.artisioCookieName, data.token, expires);
};

export const removeCurrentUser = () => {
  Vue.cookie.delete(CONFIG.artisioCookieName);
};

export const setLotFilters = (state, filters) => {
  if (filters.department && typeof filters.department === 'string') {
    filters.department = [filters.department]
  }
  if (filters.category && typeof filters.category === 'string') {
    filters.category = [filters.category]
  }

  let dynamicFields = {};

  if (filters.dynamicFields) {
    dynamicFields = Object.fromEntries(
      Object.entries(filters.dynamicFields).map(([key, value]) => [`dynamic_fields-${key}`, value])
    );
    delete filters.dynamicFields;
  }

  state.lotQueryParams = {
    ...state.lotQueryParams,
    ...filters,
    ...dynamicFields
  };
};

export const setFavoriteLotFilters = (state, filters) => {
  state.favoriteLotQueryParams = {
    ...state.favoriteLotQueryParams,
    ...filters
  };
};

export const setAuctionFilters = (state, filters) => {
  if (filters.department && typeof filters.department === 'string') {
    filters.department = [filters.department]
  }
  if (filters.category && typeof filters.category === 'string') {
    filters.category = [filters.category]
  }

  state.auctionQueryParams = {
    ...state.auctionQueryParams,
    ...filters,
  };
};

export const setFavoriteAuctionFilters = (state, filters) => {
  state.favoriteAuctionQueryParams = {
    ...state.favoriteAuctionQueryParams,
    ...filters,
  };
};

export const emptyLotFilters = (state) => {
  state.lotQueryParams = JSON.parse(JSON.stringify(LOT_DEFAULT_FILTERS));
};

export const setDepartmentsLoading = (state, loading) => (state.departments.loading = loading);
export const setDepartments = (state, data) => {
  state.departments.data = data;
};
export const setLocationStatesLoading = (state, loading) => (state.locationStates.loading = loading);
export const setLocationStates = (state, data) => {
  state.locationStates.data = data;
};
export const setCategories = (state, data) => {
  state.categories.data = data;
};
export const setDynamicFields = (state, data) => {
  state.dynamicFields.data = data;
};

export const setInvoicesLoading = (state, loading) => {
  state.invoices.loading = loading;
}

export const setInvoices = (state, data) => {
  const invoices = data;
  state.invoices.results = invoices.results;
  state.invoices.totalRows = invoices.count;
};

export const mutateInvoicePagination = (state, pagination) => {
  state.invoices.pagination = pagination;
};
export const mutateInvoiceFilters = (state, filters) => {
  state.invoices.filters = filters;
};

export const setSettlementsLoading = (state, loading) => {
  state.settlements.loading = loading;
}

export const setSettlements = (state, data) => {
  const settlements = data;
  state.settlements.results = settlements.results;
  state.settlements.totalRows = settlements.count;
};

export const mutateSettlementPagination = (state, pagination) => {
  state.settlements.pagination = pagination;
};
export const mutateSettlementFilters = (state, filters) => {
  state.settlements.filters = filters;
};

export const setItemsLoading = (state, loading) => {
  state.items.loading = loading;
}

export const setItems = (state, data) => {
  const items = data;
  state.items.results = items.results;
  state.items.totalRows = items.count;
};

export const mutateItemPagination = (state, pagination) => {
  state.items.pagination = pagination;
};
export const mutateItemFilters = (state, filters) => {
  state.items.filters = filters;
};

export const setTemporaryItemsLoading = (state, loading) => {
  state.temporaryItems.loading = loading;
}

export const setTemporaryItems = (state, data) => {
  const temporaryItems = data;
  state.temporaryItems.results = temporaryItems.results;
  state.temporaryItems.totalRows = temporaryItems.count;
};

export const mutateTemporaryItemPagination = (state, pagination) => {
  state.temporaryItems.pagination = pagination;
};
export const mutateTemporaryItemFilters = (state, filters) => {
  state.temporaryItems.filters = filters;
};

export const toggleSellAnItemModal = (state, show) => {
  state.sellAnItemModal.show = show;
}

export const setDepartmentsForSellAnItem = (state, data) => {
  state.departmentsForSellAnItem = data;
  data.forEach(department => {
    state.categoriesMap[department.key] = department.categories;
    department.categories.forEach(category => {
      const dynamicFields = [];
      for (let i in category.dynamic_fields) {
        dynamicFields.push({name: i, ...category.dynamic_fields[i]});
      }
      state.dynamicFieldsMap[category.key] = dynamicFields.sort((a, b) => a.position - b.position);
    });
  });
}

export const setError = (state, error) => {
  state.error = {...error};
}

export const setFiltersMenuOpenMobile = (state, val) => {
  document.body.style.overflow = val ? 'hidden' : 'auto';
  state.filtersMenuOpenMobile = val;
}

export const setHomeDepartmentsLoading = (state, loading) => {
  state.homePage.departments.loading = loading;
}

export const setHomeDepartments = (state, departments) => {
  state.homePage.departments.data = departments;
}

export const setPreserveOldAuctions = (state, preserve) => {
  state.lots.preserveOldAuctions = preserve
}

export const setPreserveOldLots = (state, preserve) => {
  state.lots.preserveOldLots = preserve
}

export const setCountries = (state, countries) => {
  state.countries = countries;
}

export const setTitles = (state, titles) => {
  state.titles = titles;
}

export const logoutUser = (state, preserve) => {
  Vue.cookie.delete(CONFIG.artisioCookieName);
  state.user.token = null;
  state.customer = {};
  state.is_authorized = false;
}

export const setLotBiddingStartedFinishedFlags = (state, {started, finished}) => {
  state.lotObject.biddingStarted = started;
  state.lotObject.biddingFinished = finished;
}

export const setShowMaxBidModal = (state, showModal) => {
  state.lotObject.maxBidModal = showModal
}

export const addIntoLastBids = (state, bid) => {
  const last_bids = state.lotObject.data.last_bids || [];
  const latestBid = {
    amount: bid.amount,
    auto_bid: bid.auto_bid,
    bid_date: bid.created_at,
    paddle_no: bid.paddle_no,
    uuid: bid.uuid,
  }
  state.lotObject.data = {
    ...state.lotObject.data,
    last_bids: [latestBid, ...last_bids]
  }
}

export const setSettings = (state, settings) => {
  state.settings = settings;
}

export const showUpcomingAmountPopover = (state, popover = null) => {
  const stateObjectName = popover['stateObjectName'];
  state.lotObject[stateObjectName] = Object.assign({}, state.lotObject[stateObjectName], popover);
  state.lotObject[stateObjectName].show = !state.lotObject[stateObjectName].show;
}

export const hideUpcomingAmountPopover = (state, stateObjectName) => {
  state.lotObject[stateObjectName] = Object.assign({}, state.lotObject[stateObjectName], {
    show: false,
    low: null,
    high: null
  });
}

export const setIsOnline = (state, isOnline) => {
  state.isOnline = isOnline
}

/**
 * This method will set given lot actual dates and return given lot
 * @param l
 * @param timeDiff
 * @returns {*}
 */
function setLotActualDates(l, timeDiff) {
  if (l.start_date) {
    let startEventTime = typeof l.start_date === "string" ? moment(l.start_date).toDate().getTime() : l.start_date.getTime();
    l.actual_start_date = moment(startEventTime).add(timeDiff);
  }

  if (l.end_date) {
    let endEventTime = typeof l.end_date === "string" ? moment(l.end_date).toDate().getTime() : l.end_date.getTime();
    l.actual_end_date = moment(endEventTime).add(timeDiff);
  }

  return l
}
