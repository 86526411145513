<template>
  <filter-group :title="$t('Departments')"
                v-if="!(model && model.length && mainConfig.hideDepartmentsIfSelected)"
                id="collapse-departments"
                class="ta-filter-group"
  >
    <div v-if="departments.loading" class="py-3 text-center">
      {{ $t('Loading...') }}
    </div>
    <department-list v-else
                     :departments="departments.data"
                     v-model="model"
                     @change="filtersChanged"
    />
  </filter-group>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {AUCTION_LIST_DEFAULT_PAGE} from "../../constants";
import {updateAuctionsRoute} from "../../helpers";
import DepartmentList from "../../components/lot-filters/DepartmentList";
import FilterGroup from "../../components/FilterGroup";

export default {
  name: "FilterDepartmentList",
  components: {FilterGroup, DepartmentList},
  data: () => ({
    model: []
  }),
  computed: {
    ...mapState(['auctionQueryParams', 'departments', 'mainConfig'])
  },
  watch: {
    auctionQueryParams() {
      this.model = this.auctionQueryParams.department
    },
  },
  methods: {
    ...mapMutations(['setAuctionFilters']),
    filtersChanged() {
      this.setAuctionFilters({
        department: this.model,
        page: AUCTION_LIST_DEFAULT_PAGE,
      });
      updateAuctionsRoute();
    }
  },
  mounted() {
    this.model = this.auctionQueryParams.department
  }
}
</script>

<style scoped>

</style>
