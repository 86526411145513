<template>
  <div v-if="mainConfig.showPrevNextList" class="ta-lot-next-previous d-flex justify-content-between align-items-center text-sm">
      <a href="#" :class="{
        'd-flex align-items-center': true,
        'disabled-anchor': !lotObject || !lotObject.data || !lotObject.data.previous,
        'text-muted': !lotObject || !lotObject.data || !lotObject.data.previous,
        }"
      @click.prevent="previousLot()">
        <svg xmlns="http://www.w3.org/2000/svg"
             class="w-4 mr-2"
             fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
        </svg>
        {{ $t('Previous') }}
      </a>

      <a href="#" @click.prevent="backToList()">
        {{ $t('Back to List') }}
      </a>

      <a href="#" :class="{
        'd-flex align-items-center': true,
        'disabled-anchor': !lotObject || !lotObject.data || !lotObject.data.next,
        'text-muted': !lotObject || !lotObject.data || !lotObject.data.next,
        }"
      @click.prevent="nextLot()">
        {{ $t('Next') }}
        <svg xmlns="http://www.w3.org/2000/svg"
             class="w-4 ml-2"
             fill="none" viewBox="0 0 24 24"
             stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
        </svg>
      </a>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {getLotsQuery, goBackToList} from "@/helpers";

export default {
  name: "LotViewPrevNextButtons",
  computed: {
    ...mapState(['mainConfig', 'lotObject'])
  },
  methods: {
      previousLot() {
        if (!this.lotObject || !this.lotObject.data || !this.lotObject.data.previous) {
          return;
        }
        this.navigateToLot(this.lotObject.data.previous);
      },
      nextLot() {
        if (!this.lotObject || !this.lotObject.data || !this.lotObject.data.next) {
          return;
        }
        this.navigateToLot(this.lotObject.data.next);
      },
      navigateToLot(lotno) {
        this.$router.push({name: 'LotView', params: {lotno}, query:  getLotsQuery()});
      },
      backToList() {
        return goBackToList(this.$route.query)
      }
  }
}
</script>

<style lang="scss" scoped>

#artisioTimedAuctions {
  .disabled-anchor {
    pointer-events: none;
  }
}
</style>
