<template>
  <div v-if="mainConfig.enableBidding && lot.reserve && !biddingFinished" class="ta-card-reserve-not-met">
    <div v-if="reserveMet" class="ta-reserve-met alert alert-success py-2 px-3 mb-0">
      <small class="d-flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" style="width: 20px" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" class="mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        {{ $t('Reserve reached') }}
      </small>
    </div>
    <div v-else-if="mainConfig.enableBidding" class="ta-reserve-not-met alert alert-danger py-2 px-3 mb-0">
      <small class="d-flex align-items-center">
        <svg xmlns="http://www.w3.org/2000/svg" style="width: 20px" fill="none" viewBox="0 0 24 24"
             stroke="currentColor" stroke-width="2" class="mr-2">
          <path stroke-linecap="round" stroke-linejoin="round"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
        {{ $t('Reserve not reached') }}
      </small>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import lotBiddingMixin from "../../../mixins/lotBiddingMixin";

export default {
  name: "BiddingReserveNotMet",
  mixins: [lotBiddingMixin],
  computed: {
    ...mapState(['mainConfig']),
    ...mapState({
      lot: state => state.lotObject.data,
    })
  }
}
</script>

<style scoped>

</style>
