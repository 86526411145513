<template>
  <ValidationObserver v-slot="{invalid}" ref="form">
    <b-modal
      :title="$t('Sell an item')"
      v-model="sellAnItemModal.show"
      @hide="onModalHide"
      size="lg"
      static
      scrollable
      lazy
    >
      <form @submit.stop.prevent>
        <div class="row">
          <div class="col-6">
            <input-widget
              id="idSellAnItemDepartmentInput"
              type="select"
              v-model="model.department_uuid"
              :rules="'required'"
              :options="departmentOptions"
              :label="$t('Department')"
              @change="onDepartmentChange"
            />
          </div>
          <div class="col-6">
            <input-widget
              id="idSellAnItemCategoryInput"
              type="select"
              v-model="model.category_uuid"
              :rules="'required'"
              :options="categoryOptions"
              :label="$t('Category')"
              @change="onCategoryChange"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <input-widget
              id="idSellAnItemQuantityInput"
              type="number"
              v-model="model.quantity"
              :rules="'required'"
              :label="$t('Quantity')"
            />
          </div>
          <div class="col-6">
            <input-widget
              id="idSellAnItemReserveInput"
              type="number"
              v-model="model.reserve"
              :rules="'required'"
              :label="$t('Reserve')"
            />
          </div>
        </div>
        <image-picker v-model="model.images"/>
        <div class="row" v-if="model.category_uuid">
          <template v-for="dynamicField in dynamicFields">
            <div
              :class="{
                'col-6': dynamicField.type !== 'ckeditor',
                'col-12': dynamicField.type === 'ckeditor',
              }"
              :key="dynamicField.name"
            >
              <input-widget
                :type="typeMap[dynamicField.type]"
                :label="$t(dynamicField.label)"
                :id="'idSellAnItem' + dynamicField.name"
                :rules="getRules(dynamicField)"
                :options="getOptions(dynamicField)"
                v-model="model['dynamic_fields-' + dynamicField.name]"
              />
            </div>
          </template>
        </div>
      </form>
      <template #modal-footer>
        <div class="d-flex justify-content-end">
          <b-button variant="primary" class="mr-2" @click="save(false)" :disabled="invalid">
            {{ $t("Save") }}
          </b-button>
          <b-button variant="success" class="mr-2" @click="save(true)" :disabled="invalid">
            {{ $t("Save and add new") }}
          </b-button>
          <b-button variant="secondary" @click="close">
            {{ $t("Close") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
import i18n from '../i18n';
import logger from "../logger";
import InputWidget from "./core/InputWidget.vue";
import ImagePicker from "./core/ImagePicker";
import {saveTemporaryItem} from "../store/actions";
import store from "../store"

const INITIAL_MODEL = {
  department_uuid: "",
  category_uuid: "",
  reserve: null,
  quantity: null,
  language: store.getters.currentLocale,
  images: []
}

export default {
  name: "SellAnItemModal",
  components: {ImagePicker, InputWidget},
  data: () => {
    return {
      model: JSON.parse(JSON.stringify(INITIAL_MODEL)),
      typeMap: {
        'text': 'text',
        'number': 'number',
        'select2': 'select',
        'ckeditor': 'textarea',
        'checkbox': 'checkbox',
      }
    };
  },
  computed: {
    ...mapState([
      "sellAnItemModal",
      "departmentsForSellAnItem",
      "categoriesMap",
      "dynamicFieldsMap",
    ]),
    ...mapGetters(["currentLocale"]),
    dynamicFields() {
      return (this.dynamicFieldsMap[this.model.category_uuid] || []).filter(f => !f.hide_on_website)
    },
    departmentOptions() {
      const placeholder = {value: "", text: this.$t('Please select department')};
      if (!this.departmentsForSellAnItem) {
        return [placeholder];
      }
      return [
        placeholder,
        ...this.departmentsForSellAnItem.map(department => ({
          value: department.key,
          text: this.$options.filters.currentLocale(department.name)
        }))
      ]
    },
    categoryOptions() {
      const placeholder = {value: "", text: this.$t('Please select category')};
      if (!this.model.department_uuid) {
        return [placeholder];
      }
      return [
        placeholder,
        ...this.categoriesMap[this.model.department_uuid].map(category => ({
          value: category.key,
          text: this.$options.filters.currentLocale(category.name)
        }))
      ]
    }
  },
  methods: {
    ...mapMutations(["toggleSellAnItemModal", 'showNotification']),
    ...mapActions(["getDepartmentsForSellAnItem", "saveTemporaryItem", 'getTemporaryItems']),
    close() {
      this.toggleSellAnItemModal(false);
    },
    onModalHide() {
      this.resetForm();
    },
    resetForm() {
      this.model = JSON.parse(JSON.stringify(INITIAL_MODEL));
      // Wait until the models are updated in the UI
      this.$nextTick(() => {
        this.$refs.form.reset();
      });
    },
    async save(addNew) {
      const isValid = await this.$refs.form.validate();
      console.log(JSON.stringify(this.model));
      if (!isValid) {
        return;
      }
      const formData = new FormData();
      for (let key in this.model) {
        if (key !== 'images') {
          formData.append(key, (this.model[key] || ''));
        } else {
          for (let img of this.model.images) {
            formData.append('images', img)
          }
        }
      }

      const res = await this.saveTemporaryItem(formData)
      if (res === false) {
        this.showNotification({
          title: i18n.t('Server error.'),
          text: i18n.t("Server error. Please try again."),
          variant: 'danger',
          duration: 5000
        });
      } else {
        this.showNotification({
          title: i18n.t('Item saved.'),
          text: i18n.t("Your item has been submitted."),
          variant: 'success',
          duration: 5000
        });
        this.getTemporaryItems();
        if (addNew) {
          this.resetForm()
        } else {
          this.close();
        }
      }
    },
    getRules(dynamicField) {
      let rules = [];
      for (let i in dynamicField.rules) {
        if (i === "required" && dynamicField.rules[i]) {
          rules.push("required");
        } else if (i === 'min_value' || i === 'max_value') {
          rules.push(`${i}:${dynamicField.rules[i]}`);
        } else if (i === 'min_length' || i === 'max_length') {
          rules.push(`${i === 'min_length' ? 'min' : 'max'}:${dynamicField.rules[i]}`);
        } else if (i === 'choices') {
          rules.push('oneOf:' + dynamicField.rules[i].map(({value}) => value).join(','))
        }
      }
      return rules.join("|");
    },
    getOptions(dynamicField) {
      if (dynamicField.type !== 'select2') {
        return [];
      }
      return [
        {value: "", text: this.$t("Please Select")},
        ...dynamicField.rules.choices.map((choice) => {
          return {value: choice.value, text: choice.key}
        })
      ];
    },
    onDepartmentChange() {
      this.model.category_uuid = "";
    },
    onCategoryChange() {
      for (let key in this.model) {
        if (key.indexOf('dynamic_fields-') === 0) {
          this.model[key] = this.model[key] || "";
        }
      }
    }
  },
  mounted() {
    this.getDepartmentsForSellAnItem(false);
  },
};
</script>
<style scoped lang="scss">
</style>
