<template>
  <abstract-signup-text-input
    :id="`input-${type}_address_city`"
    v-model="model"
    :errors="errors"
    :label="computedLabel"
    :placeholder="computedPlaceholder"
    @change="inputChange"
    rules="required"
  />
</template>

<script>
import BaseSignupTextInput from "../core/AbstractSignupTextInput";
import AbstractSignupTextInput from "../core/AbstractSignupTextInput";
import BaseAddressMixin from "../../../../mixins/baseAddressMixin";

export default {
  name: "InputSignupCity",
  components: {AbstractSignupTextInput, BaseSignupTextInput},
  mixins: [BaseAddressMixin],

  data() {
    return {
      defaultLabel: this.$t("City")
    }
  }
}
</script>

<style scoped>

</style>
