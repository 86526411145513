<template>
  <InputWidget
    v-model="model"
    :error="errors"
    id="input-bank_account_account_number"
    type="text"
    :label="computedLabel"
    :placeholder="computedPlaceholder"
    @change="inputChange"
    :rules="`${bankAccountFilled ? 'required' : ''}`"
  />
</template>

<script>
import InputWidget from "../../../core/InputWidget";
import BaseBankAccountMixin from "../../../../mixins/baseBankAccountMixin";

export default {
  name: "InputSignupBankAccountNumber",
  components: {InputWidget},
  mixins: [BaseBankAccountMixin],

  data() {
    return {
      defaultLabel: this.$t("Account Number")
    }
  }
}
</script>

<style scoped>

</style>
