import BaseInputMixin from "./baseInputMixin";

export default {
  mixins: [BaseInputMixin],
  props: {
    type: {
      required: true,
      type: String,
      validator(value) {
        return ['billing', 'shipping'].includes(value)
      }
    }
  }
}
