<template>
  <h4 class="ta-lot-list-item-title font-weight-bold">
    <router-link :to="auctionViewRoute" v-html="title">
    </router-link>
  </h4>
</template>

<script>
import auctionViewRoute from "@/mixins/auctionViewRoute";

export default {
  name: "AuctionTitle",
  mixins: [auctionViewRoute],
  computed: {
    title(){
      return this.$options.filters.stripTags(this.$options.filters.currentLocale(this.auction.title)).trim().replace(/^&nbsp;/, '');
    }
  }
}
</script>

<style scoped>

</style>
