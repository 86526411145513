import {getCookie, hideHTMLElements, openLogin, showHTMLElements} from "../helpers";
import {COOKIE_AUTH_KEY} from "../constants";
import router from "@/router";
import CONFIG from "@/user-config";
import store from "@/store";

export default {

  loggedIn() {
    return !!getCookie(COOKIE_AUTH_KEY);
  },

  logout() {
    hideHTMLElements(CONFIG.visibleForAuthSelector);
    showHTMLElements(CONFIG.visibleForGuestsSelector);

    store.commit('removeCurrentUser', null);
    openLogin()
  }
}
