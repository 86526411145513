<template>
  <filter-group :title="$t('Reserve Range')" id="collapse-for-reserve">
    <div class="row">
      <div class="col">
        <b-form-input
          type="number"
          v-model="reserve_low"
          v-debounce:1000ms="filtersChanged"
          :placeholder="$t('Low')"
        ></b-form-input>
      </div>
      <div class="col">
        <b-form-input
          type="number"
          v-model="reserve_high"
          v-debounce:1000ms="filtersChanged"
          :placeholder="$t('High')"
        ></b-form-input>
      </div>
    </div>
  </filter-group>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {LOT_LIST_DEFAULT_PAGE} from "../../../constants";
import {updateLotsRoute} from "../../../helpers";
import FilterGroup from "../../FilterGroup";
import userConfig from "@/user-config";

export default {
  name: "FilterReserveRange",
  components: {FilterGroup},
  data: () => ({
    reserve_low: null,
    reserve_high: null,
  }),
  computed: {
    ...mapState(['lotQueryParams']),
  },
  watch: {
    lotQueryParams() {
      this.reserve_low = this.lotQueryParams.reserve_low
      this.reserve_high = this.lotQueryParams.reserve_high
    },
  },
  methods: {
    ...mapMutations(['setLotFilters']),
    filtersChanged() {
      this.setLotFilters({
        reserve_low: this.reserve_low,
        reserve_high: this.reserve_high,
        page: LOT_LIST_DEFAULT_PAGE,
      });
      if (userConfig.autoApplyFilters) {
        updateLotsRoute();
      }
    }
  },
  mounted() {
    this.reserve_low = this.lotQueryParams.reserve_low
    this.reserve_high = this.lotQueryParams.reserve_high
  }
}
</script>

<style scoped>

</style>
