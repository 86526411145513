<template>
  <div class="ta-page-temporary-items">
    <PageHeader :title="$t('Temporary Items')">
      <b-button v-if="enableEstimateRequest" variant="primary" @click="toggleSellAnItemModal(true)">
        <svg
          style="width: 20px"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          ></path>
        </svg>
        {{ $t("Sell an item") }}
      </b-button>
    </PageHeader>

    <div class="d-flex justify-content-start">
      <div class="ta-temporary-item-list">
        <div class="ta-temporary-item-list-header">
          <div class="ta-per-page-wrapper">
            {{ $t("Show") }}
            <b-form-select
              class="mx-2"
              v-model="limit"
              @change="updatePagination"
              :options="perPageOptions"
            ></b-form-select>
            {{ $t("entries") }}
          </div>
          <div
            class="ta-temporary-item-search-wrapper d-flex align-items-center"
          >
            {{ $t("Search") }}:
            <b-form-input
              :placeholder="$t('Search for temporary items')"
              class="ml-2"
              v-model="filters.q"
              v-debounce:300ms="updateRoute"
            ></b-form-input>
          </div>
        </div>
        <div v-if="temporaryItems.loading" class="ta-temporary-item-loader">
          <b-spinner label="Spinning"></b-spinner>
          <span class="mt-2">{{ $t("Loading...") }}</span>
        </div>
        <template v-if="!temporaryItems.loading">
          <div class="ta-temporary-item-list-wrapper">
            <table class="table">
              <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">
                  {{ $t("Title") }}
                </th>
                <th scope="col">
                  {{ $t("Department") }}
                </th>
                <th scope="col">
                  {{ $t("Category") }}
                </th>
                <th scope="col">
                  {{ $t("Quantity") }}
                </th>
                <th scope="col">
                  {{ $t("Low") }}
                </th>
                <th scope="col">
                  {{ $t("High") }}
                </th>

                <th scope="col">
                  {{ $t("Start Price") }}
                </th>
                <th scope="col">
                  {{ $t("Reserve") }}
                </th>
              </tr>
              </thead>
              <tbody v-if="temporaryItems.results.length">
              <tr v-for="item in temporaryItems.results" :key="item.uuid">
                <td></td>
                <td v-html="item.short_description"></td>
                <td>
                  {{ item.department.name | currentLocale }}
                </td>
                <td>
                  {{ item.category.name | currentLocale }}
                </td>
                <td>
                  {{ item.quantity }}
                </td>
                <td>
                  {{ item.low | asCurrency(item.branch.currency.code) }}
                </td>
                <td>
                  {{ item.high | asCurrency(item.branch.currency.code) }}
                </td>
                <td>
                  {{
                    item.start_price | asCurrency(item.branch.currency.code)
                  }}
                </td>
                <td>
                  {{ item.reserve | asCurrency(item.branch.currency.code) }}
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="15">
                  <p class="text-muted text-center py-3">
                    {{ $t(`You don't have data`) }}
                  </p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="ta-temporary-item-list-footer">
            <div v-if="temporaryItems.totalRows"
                 class="ta-temporary-item-pagination-summary">
              {{
                $t("Showing {start} to {finish} of {total} entries", {
                  start:
                    (temporaryItems.pagination.page - 1) *
                    temporaryItems.pagination.limit +
                    1,
                  finish:
                    temporaryItems.pagination.page *
                    temporaryItems.pagination.limit >
                    temporaryItems.totalRows
                      ? temporaryItems.totalRows
                      : temporaryItems.pagination.page *
                      temporaryItems.pagination.limit,
                  total: temporaryItems.totalRows,
                })
              }}
            </div>
            <b-pagination
              v-if="temporaryItems.totalRows > temporaryItems.pagination.limit"
              v-model="page"
              :total-rows="temporaryItems.totalRows"
              :per-page="temporaryItems.pagination.limit"
              @input="updatePagination"
              class="ta-temporary-item-list-pagination"
            ></b-pagination>
          </div>
        </template>
      </div>
    </div>
    <sell-an-item-modal/>
  </div>
</template>

<script>
import SellAnItemModal from '@/components/SellAnItemModal';
import {mapState, mapGetters, mapActions, mapMutations} from "vuex";
import {
  TEMPORARY_ITEM_PAGINATION_PAGE,
  TEMPORARY_ITEM_PAGINATION_LIMIT,
} from "../constants";
import logger from "../logger";
import tablePagination from "../mixins/tablePagination";
import PageHeader from "@/components/PageHeader";

export default {
  name: "Items",
  components: {PageHeader, SellAnItemModal},
  mixins: [tablePagination],
  data: () => {
    return {
      modal: {
        show: false,
        title: "",
        base64: "",
      },
      delayedRequest: null,
      query: null,
      normalizedQuery: null,
    };
  },

  computed: {
    ...mapState(["perPageOptions", "temporaryItems"]),
    ...mapGetters(["currentLocale", "customerMeta"]),
    enableEstimateRequest() {
      return this.customerMeta.enable_estimate_request;
    }
  },

  methods: {
    ...mapActions(["getTemporaryItems", "socketLoginCallback"]),
    ...mapMutations(["toggleSellAnItemModal"]),
    ...mapMutations({
      mutatePagination: 'mutateTemporaryItemPagination',
      mutateFilters: 'mutateTemporaryItemFilters',
    }),

    getUpdatedItems() {
      this.getTemporaryItems({
        pagination: {page: this.page, limit: this.limit},
        filters: this.filters,
      });
    },
  }
};
</script>

<style scoped lang="scss">
.ta-temporary-item-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.ta-temporary-item-loader {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ta-per-page-wrapper {
  display: flex;
  align-items: center;
}

.ta-temporary-item-list {
  flex: 1;
}

.ta-temporary-item-list-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ta-uc-first {
  text-transform: capitalize;
}

.pagination.ta-temporary-item-list-pagination {
  margin-bottom: 0 !important;
}
</style>
