<template>
    <router-link :to="lotInnerPageRoute" class="btn btn-outline-primary ta-btn-lot-view">
      {{ label }}
    </router-link>
</template>

<script>

import lotViewRoute from "@/mixins/lotViewRoute";

export default {
  name: "LotViewButton",
  mixins: [lotViewRoute],
  props: {
    label: {
      type: String,
      default() {
        return this.$t('View Details')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ta-btn-lot-view {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
}
</style>
