<script>

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {AUCTION_DEFAULT_STATUS, AUCTION_LIST_DEFAULT_PAGE} from "../../constants";
import {updateAuctionsRoute} from "../../helpers";
import SidebarFilters from "@/components/SidebarFilters";
import templatable from "@/mixins/templatable";
import FilterAuctionStatus from './FilterAuctionStatus.vue'
import FilterDepartmentList from './FilterDepartmentList.vue'

export default {
  name: "AuctionListFilters",
  components: {
    FilterAuctionStatus,
    FilterDepartmentList,
    SidebarFilters,
  },
  mixins: [templatable('auctionFiltersTemplate')],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      template: `
        <sidebar-filters :filtersApplied="filtersApplied" @clear-filters="clearFilters">
          [[template]]
        </sidebar-filters>
      `,
      dynamic_fields: {}
    };
  },

  computed: {
    ...mapState(["auctionQueryParams", "departments", "categories", "mainConfig", "filtersMenuOpenMobile"]),
    ...mapGetters(["currentLocale", "selectedDepartment", "filterableDynamicFields"]),

    filtersApplied() {
      for (const key in this.auctionQueryParams) {
        if (key === "page" || key === "limit" || key === 'sort' || key === 'sort-by') {
          continue;
        }
        // If it is an empty array
        if (Array.isArray(this.auctionQueryParams[key]) && !this.auctionQueryParams[key].length || key === 'status' && this.auctionQueryParams[key] === AUCTION_DEFAULT_STATUS) {
          continue;
        }
        if (
          (Array.isArray(this.auctionQueryParams[key]) && this.auctionQueryParams[key].length) ||
          this.auctionQueryParams[key]
        ) {
          return true;
        }
      }
      return false;
    },
  },

  watch: {
    auctionQueryParams() {
      this.updateFiltersFromVuex();
    },
  },

  methods: {
    ...mapMutations(["setAuctionFilters", "setDepartments", "setCategories", "setDynamicFields"]),

    filtersChanged() {
      this.setAuctionFilters({
        page: AUCTION_LIST_DEFAULT_PAGE,
      });
      updateAuctionsRoute();
    },
    clearFilters() {
      Object.keys(this.auctionQueryParams).forEach((field) => {
        if (field === "page" || field === "limit" || field === 'sort' || field === 'sort-by') {
          return;
        }
        if (Array.isArray(this.auctionQueryParams[field])) {
          this.auctionQueryParams[field] = [];
        } else {
          switch (field) {
            case "status":
              this.auctionQueryParams[field] = AUCTION_DEFAULT_STATUS;
              break;
            default:
              this.auctionQueryParams[field] = null;
          }
        }
      });

      Object.keys(this.dynamic_fields).forEach((field) => {
        if (Array.isArray(this.dynamic_fields[field])) {
          this.dynamic_fields[field] = [];
        } else {
          this.dynamic_fields[field] = null;
        }
      });

      this.filtersChanged();
      this.setDepartments([])
      this.setCategories([])
      this.setDynamicFields([])
    },
    updateFiltersFromVuex() {
      const query = this.auctionQueryParams;

      this.dynamic_fields = {};
      for (let key in query) {
        // If query parameter starts with `dynamic_fields`
        if (key.indexOf('dynamic_fields-') === 0) {
          this.dynamic_fields[key.replace('dynamic_fields-', '')] = query[key];
        }
      }
      this.dynamic_fields = {...this.dynamic_fields};
    },
  },
  mounted() {
    this.updateFiltersFromVuex();
  },
};
</script>

<style lang="scss" scoped>
</style>
