<script>
import {mapActions} from 'vuex';
import FormAlert from "../../core/FormAlert";
import LoadingButton from "../../core/LoadingButton";
import SignupFormSaveButton from "./SignupFormSaveButton";
import SignupFormResetButton from "./SignupFormResetButton";
import templatable from "@/mixins/templatable";
import InputSignupBankAccountAddress3
  from "@/components/configuration/signup/bank-account/InputSignupBankAccountAddress3";
import InputSignupBankAccountAddress2
  from "@/components/configuration/signup/bank-account/InputSignupBankAccountAddress2";
import InputSignupBankAccountAddress1
  from "@/components/configuration/signup/bank-account/InputSignupBankAccountAddress1";
import InputSignupBankAccountBankName
  from "@/components/configuration/signup/bank-account/InputSignupBankAccountBankName";
import InputSignupBankAccountSwift from "@/components/configuration/signup/bank-account/InputSignupBankAccountSwift";
import InputSignupBankAccountIban from "@/components/configuration/signup/bank-account/InputSignupBankAccountIban";
import InputSignupBankAccountNumber from "@/components/configuration/signup/bank-account/InputSignupBankAccountNumber";
import InputSignupBankAccountSortCode
  from "@/components/configuration/signup/bank-account/InputSignupBankAccountSortCode";
import InputSignupBankAccountBeneficiary
  from "@/components/configuration/signup/bank-account/InputSignupBankAccountBeneficiary";
import InputSignupSameAsBillingAddress from "@/components/configuration/signup/address/InputSignupSameAsBillingAddress";
import InputSignupZipCode from "@/components/configuration/signup/address/InputSignupZipCode";
import InputSignupCity from "@/components/configuration/signup/address/InputSignupCity";
import InputSignupState from "@/components/configuration/signup/address/InputSignupState";
import InputSignupCountry from "@/components/configuration/signup/address/InputSignupCountry";
import InputSignupAddress2 from "@/components/configuration/signup/address/InputSignupAddress2";
import InputSignupAddress1 from "@/components/configuration/signup/address/InputSignupAddress1";
import InputSignupIsCompany from "@/components/configuration/signup/profile-information/InputSignupIsCompany";
import InputSignupPhone from "@/components/configuration/signup/profile-information/InputSignupPhone";
import InputSignupCompanyName from "@/components/configuration/signup/profile-information/InputSignupCompanyName";
import InputSignupPersonalId from "@/components/configuration/signup/profile-information/InputSignupPersonalId";
import InputSignupTitle from "@/components/configuration/signup/profile-information/InputSignupTitle";
import InputSignupLastName from "@/components/configuration/signup/profile-information/InputSignupLastName";
import InputSignupMiddleName from "@/components/configuration/signup/profile-information/InputSignupMiddleName";
import InputSignupFirstName from "@/components/configuration/signup/profile-information/InputSignupFirstName";
import InputSignupEmail from "@/components/configuration/signup/account-details/InputSignupEmail";
import InputSignupPassword from "@/components/configuration/signup/account-details/InputSignupPassword";
import InputSignupPasswordConfirmation
  from "@/components/configuration/signup/account-details/InputSignupPasswordConfirmation";


export default {
  name: "SignupBody",
  mixins: [templatable('signupInputsTemplate')],
  components: {
    InputSignupEmail,
    InputSignupPassword,
    InputSignupPasswordConfirmation,
    InputSignupBankAccountAddress3,
    InputSignupBankAccountAddress2,
    InputSignupBankAccountAddress1,
    InputSignupBankAccountBankName,
    InputSignupBankAccountSwift,
    InputSignupBankAccountIban,
    InputSignupBankAccountNumber,
    InputSignupBankAccountSortCode,
    InputSignupBankAccountBeneficiary,
    InputSignupSameAsBillingAddress,
    InputSignupZipCode,
    InputSignupCity,
    InputSignupState,
    InputSignupCountry,
    InputSignupAddress2,
    InputSignupAddress1,
    InputSignupIsCompany,
    InputSignupPhone,
    InputSignupCompanyName,
    InputSignupPersonalId,
    InputSignupTitle,
    InputSignupLastName,
    InputSignupMiddleName,
    InputSignupFirstName,
    SignupFormResetButton,
    SignupFormSaveButton,
    LoadingButton,
    FormAlert,
  },
  data: () => ({
    sameAsBilling: false,
    bankAccountFilled: false,
    model: null,
    paymentCompleted: false,
    template: `
      <div  v-if="model" class="ta-signup-body">
        [[template]]
      </div>
    `
  }),
  props: {
    value: {
      required: true,
      type: Object
    },
    errors: {
      required: false,
      type: Object
    }
  },
  watch: {
    model: {
      deep: true,
      handler() {
        console.log("Updated");
        this.$emit('input', JSON.parse(JSON.stringify(this.model)))
      }
    },
    sameAsBilling(newValue) {
      switch (newValue) {
        case "accepted":
          this.model.shipping_address = Object.assign({}, this.model.shipping_address, {...this.model.billing_address});
          break;
        default:
          Object.keys(this.model.shipping_address).forEach(key => this.model.shipping_address[key] = "");
          break;
      }
    },
    'model.bank_account': {
      deep: true,
      handler(newValue) {
        this.bankAccountFilled = Object.keys(newValue).filter(key => newValue[key] !== '').length > 0
        this.$emit('back-account-update', this.bankAccountFilled)
      }
    },
  },
  methods: {
    ...mapActions(["getCountries", "getTitles"]),
    eraseBankAccount() {
      Object.keys(this.model.bank_account).forEach(key => {
        this.model.bank_account[key] = ""
      });
    },
  },
  async beforeMount() {
    await this.getCountries();
    await this.getTitles();
  },
  mounted() {
    this.model = this.value;
  }
}
</script>

<style scoped>

</style>
