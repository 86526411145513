<template>
  <b-modal id="latestBidsModal" static ok-only :ok-title="$t('Close')" @close="$emit('input', false)">
    <template #modal-header="{ close }">
      <h5 class="modal-title">{{ $t('Latest Bids') }}</h5>
      <ModalCloseButton @click="close" />
    </template>
    <ModalLotDetails :lot="lot" />
    <table class="table table-sm table-bordered mb-0">
      <thead>
      <tr>
        <th class="col-auto">{{ $t('Auto') }}</th>
        <th class="col-number">{{ $t('Number') }}</th>
        <th class="col-amount">{{ $t('Amount') }}</th>
        <th class="col-date">{{ $t('Date/Time') }}</th>
      </tr>
      </thead>
      <tbody v-if="lot.last_bids && lot.last_bids.length">
      <tr v-for="bid in lot.last_bids" :key="bid.id">
        <td class="col-auto">{{ bid.auto_bid ? 'A' : '-' }}</td>
        <td class="col-number">{{ bid.paddle_no }}</td>
        <td class="col-amount">{{ bid.amount | asCurrency(lot.currency.code) }}</td>
        <td class="col-date">{{ (bid.bid_date || bid.created_at) | datetimeWithSeconds }}</td>
      </tr>
      </tbody>
      <tbody v-else>
      <tr>
        <td colspan="4" class="text-muted text-center py-3">
          {{ $t('There are no bids on this lot') }}
        </td>
      </tr>
      </tbody>
    </table>
    <small class="ta-automatic-bid-explanation">{{ $t('A - Automatic Bid placed through Max Bid') }}</small>
  </b-modal>
</template>

<script>
import {mapState} from "vuex";
import lotBiddingMixin from "../../mixins/lotBiddingMixin";
import thumbnailMixin from "@/mixins/thumbnailMixin";
import ModalLotDetails from "@/components/core/ModalLotDetails";
import ModalCloseButton from "@/components/core/ModalCloseButton";

export default {
  name: "LatestBidsModal",
  components: {ModalCloseButton, ModalLotDetails},
  mixins: [lotBiddingMixin, thumbnailMixin],
  computed: {
    ...mapState(['mainConfig']),
    ...mapState({
      lot: state => state.lotObject.data
    }),
    latestBids() {
      return this.lot.last_bids ? this.lot.last_bids.slice(0, 5) : []
    }
  }
}
</script>

<style scoped lang="scss">
.ta-lot-latest-bids {
  font-size: 90%;
}

.ta-automatic-bid-explanation {
  display: block;
  margin: 1rem 0;
}

</style>

<style lang="scss">
@import "../../bootstrap/import";

</style>
