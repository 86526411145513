<template>
  <span v-if="end_date && finished" class="ta-countdown ta-countdown-ended">
    {{ $t("Ended") }}
  </span>
  <div v-else class="ta-countdown" :class="badgeClass">
    <small v-if="!started && label" class="mr-2 ta-countdown-label">{{ $t("Opens in") }}</small>
    <small v-if="started && !finished && label" class="mr-2 ta-countdown-label">{{ $t("Ends in") }}</small>
    <div class="ta-countdown-value">
      <svg
        fill="currentColor"
        class="mr-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        width="16"
        height="16"
      >
        <path
          fill-rule="evenodd"
          d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 0a8 8 0 100 16A8 8 0 008 0zm.5 4.75a.75.75 0 00-1.5 0v3.5a.75.75 0 00.471.696l2.5 1a.75.75 0 00.557-1.392L8.5 7.742V4.75z"
        ></path>
      </svg>
      {{formattedTime}}
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {mapState} from "vuex";

export default {
  name: "CountDownTimer",
  props: {
    start_date: {
      type: [String, Date],
      required: true,
    },

    end_date: {
      type: [String, Date],
      required: true,
    },
    label: {
      type: [Boolean],
      default: true
    }
  },
  data() {
    return {
      myDatetime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      interval: null,
      finished: false,
      started: false,

      badgeClass: null,
    };
  },
  computed: {
    ...mapState(["timeDiff"]),

    startOfEventTime() {
      let startEventTime =
        typeof this.start_date === "string"
          ? moment(this.start_date).toDate().getTime()
          : this.start_date.getTime();
      return moment(startEventTime).add(this.timeDiff);
    },

    endOfEventTime() {
      let endEventTime =
        typeof this.end_date === "string"
          ? moment(this.end_date).toDate().getTime()
          : this.end_date.getTime();
      return moment(endEventTime).add(this.timeDiff);
    },
    formattedTime() {
      let result = [];
      if (this.end_date && this.myDatetime.days) {
        result.push(`${this.myDatetime.days}d`)
      }
      if (this.myDatetime.days || this.myDatetime.hours) {
        result.push(`${this.myDatetime.hours}h`)
      }
      if (!this.myDatetime.days) {
        result.push(`${this.myDatetime.minutes}m`)
      }
      if (!this.myDatetime.days && !this.myDatetime.hours) {
        result.push(`${this.myDatetime.seconds}s`)
      }
      return result.join(' ')
    }
  },
  methods: {
    updateUI() {
      const currentTime = Date.now();
      this.started = this.startOfEventTime <= currentTime;
      this.finished = false;

      const eventTime = !this.started
        ? this.startOfEventTime
        : this.endOfEventTime;
      this.badgeClass = !this.started
        ? "ta-countdown-not-started"
        : "ta-countdown-started";

      if (currentTime > this.endOfEventTime) {
        // clearInterval(this.interval);
        this.finished = true;
        return;
      }
      const diffTime = eventTime - currentTime;
      const duration = moment.duration(diffTime, "milliseconds");
      this.myDatetime = {
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      };
    },
  },
  mounted() {
    if (!this.start_date || !this.end_date) {
      return;
    }
    this.updateUI();
    const interval = 1000;

    this.interval = setInterval(() => {
      this.updateUI();
    }, interval);
  },

  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped lang="scss">
@import "../bootstrap/import.scss";

#artisioTimedAuctions {
  .ta-countdown {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 90%;
    white-space: nowrap;
    //background: fade-out($dark, 0.2);
    //color: $white;
  }

  .ta-countdown-value {
    display: flex;
    align-items: center;
  }
}
</style>
