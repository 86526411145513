<template>
  <div class="ta-modal-lot-details">
    <div class="ta-modal-lot-details-content">
      <h4 class="ta-modal-lot-details-title" v-html="$options.filters.currentLocale(lot.title)"></h4>
      <p class="ta-modal-lot-details-subtitle" v-html="$options.filters.currentLocale(lot.short_description)"></p>
    </div>
    <img
      v-if="thumbnailSrc"
      :src="thumbnailSrc"
      class="ta-modal-lot-details-image"
    />
    <img v-else src="https://cdn.artisio.co/no_image.jpg" class="ta-modal-lot-details-image">
  </div>
</template>

<script>
import thumbnailMixin from "@/mixins/thumbnailMixin";

export default {
  name: "ModalLotDetails",
  mixins: [thumbnailMixin],
  props: {
    lot: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.ta-modal-lot-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.ta-modal-lot-details-image {
  width: 120px;
  height: 80px;
  object-fit: cover;
  border-radius: 10px;
}

</style>
