<template>
  <button class="btn-link ta-btn-icon ta-btn-close" @click="$emit('click')">
    <svg data-v-a83a2838="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
         style="width: 22px;">
      <path data-v-a83a2838="" fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"></path>
    </svg>
  </button>
</template>

<script>
export default {
  name: "ModalCloseButton"
}
</script>

<style lang="scss" scoped>
@import "../../scss/mixins";

#artisioTimedAuctions {
  #taMsgModal {
    .ta-btn-close {
      @include btn-close();
    }
  }
}
</style>
