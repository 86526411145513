<template>
  <div v-if="mainConfig.showBreadcrumbs" class="flex-grow-1">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb text-small text-nowrap">
        <li v-for="(item, ind) of breadcrumbs" :key="item.key" class="breadcrumb-item"
            :class="`breadcrumb-item-${item.key}`">
          <span v-if="ind === breadcrumbs.length - 1" href="#" v-html="item.label" class="text-muted"></span>
          <a v-else-if="item.key === 'home' && mainConfig.homeUrl" :href="mainConfig.homeUrl" v-html="item.label"></a>
          <router-link v-else-if="item.path.name" :to="item.path" v-html="item.label"></router-link>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "LotViewBreadcrumbs",
  computed: {
    ...mapState(['mainConfig']),
    ...mapGetters(['settings']),
    ...mapState({
      lot: state => state.lotObject.data
    }),
    breadcrumbs() {
      let innerBreadcrumbs = [];
      if (!this.settings) {
        return [];
      }
      if (parseInt(this.settings.is_continious_auction)) {
        innerBreadcrumbs = [
          {
            label: this.$t('breadcrumb.lots'),
            path: {name: 'Lots'},
            key: 'lots'
          },
          {
            label: this.$options.filters.stripTags(this.$options.filters.currentLocale(this.lot.department.name)),
            path: {name: 'Lots', query: {department: this.lot.department.key}},
            key: 'lotsByDepartment'
          }
        ];
      } else {
        innerBreadcrumbs = [
          {
            label: this.$t('breadcrumb.auctions'),
            path: {name: 'AuctionsTimed'},
            key: 'auctions'
          },
          {
            label: this.$options.filters.stripTags(this.$options.filters.currentLocale(this.lot.auction.department.name)),
            path: {name: 'AuctionsTimed', query: {department: this.lot.auction.department.key}},
            key: 'auctionsByDepartment'
          },
          {
            label: this.$t('breadcrumb.saleno {saleno}', {saleno: this.lot.auction.auction_no}),
            path: {name: 'AuctionViewTimed', params: {uuid: this.lot.auction.uuid}},
            key: 'auctionView'
          },
          {
            label: this.$options.filters.stripTags(this.$options.filters.currentLocale(this.lot.department.name)),
            path: {
              name: 'AuctionViewTimed',
              params: {uuid: this.lot.auction.uuid},
              query: {department: this.lot.department.key}
            },
            key: 'auctionLotsByDepartment'
          }
        ];
      }

      return [
        {
          label: this.$t('breadcrumb.home'),
          path: {name: 'Home'},
          key: 'home'
        },
        ...innerBreadcrumbs,
        {
          label: this.$options.filters.stripTags(this.$options.filters.currentLocale(this.lot.title)),
          path: {name: 'LotView', params: {lotno: this.lot.uuid}},
          key: 'lotView'
        },
      ];
    }
  }
}
</script>

<style lang="scss" scoped>
#artisioTimedAuctions {
  .breadcrumb {
    margin-bottom: 0;
    background-color: transparent;
    padding: 0.5rem 0;
  }
}
</style>
