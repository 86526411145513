<template>
  <h4 class="ta-lot-list-item-title font-weight-bold">
    <router-link :to="lotInnerPageRoute" v-html="title">
    </router-link>
  </h4>
</template>

<script>
import lotViewRoute from "@/mixins/lotViewRoute";

export default {
  name: "LotListItemTitle",
  mixins: [lotViewRoute],
  computed: {
    title(){
      return this.$options.filters.stripTags(this.$options.filters.currentLocale(this.lot.title)).trim().replace(/^&nbsp;/, '')
    }
  }
}
</script>

<style scoped>

</style>
