<template>
  <div class="ta-auction-list-item-thumb">
    <router-link :to="auctionViewRoute">
      <img v-if="imageSrc" :src="imageSrc" />
      <img v-else src="https://cdn.artisio.co/no_image.jpg">
    </router-link>
  </div>
</template>

<script>
import auctionViewRoute from "@/mixins/auctionViewRoute";

export default {
  name: "AuctionImage",
  mixins: [auctionViewRoute],
  computed: {
    imageSrc() {
      if (!this.auction.images || !this.auction.images.length) {
        return 'https://cdn.artisio.co/no_image.jpg';
      }
      return this.auction.images[0].sm.url;
    },
  }
}
</script>

<style scoped>

</style>
